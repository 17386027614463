<div class="backdrop"></div>
<div class="loading-spinner">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div>Loading Data</div>
</div>