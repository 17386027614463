

      <div class="nav_icon" (click)="addToggle()">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </div>
      <div class="navbar__left">
        <!-- <a href="#">Subscribers</a> -->
        <!-- <a href="#">Video Management</a> -->
        <a class="active_link" href="#">Admin</a>
      </div>
      <div class="navbar__right">
        <a href="#">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a>
        <!-- <a href="#">
          <i class="fa fa-clock-o" aria-hidden="true"></i>
        </a> -->
        <a href="#">
           <img width="30" src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g">
          
        </a>
      </div>