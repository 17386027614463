import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transfered-in',
  templateUrl: './transfered-in.component.html',
  styleUrls: ['./transfered-in.component.css',"../dashbboard-style.css"]
})
export class TransferedInComponent implements OnInit {
  status = false;
  addToggle() {
    this.status = !this.status;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
