import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SharedService } from '../shared.service';
import { WebUtils } from '../shared/web-utils';
import { keys } from 'underscore'
import { Alert } from '../shared/message-helper';

@Component({
  selector: 'add-dependant',
  templateUrl: './add-dependant.component.html',
  styleUrls: ['./add-dependant.component.css']
})
export class AddDependantComponent implements OnInit {
  @Input() dep: any;
  depID: any;
  MemberID: any;
  dependandID: any;
memberId:any;
churchId:any;
  mechurchID: any;
  // signUpForm!: FormGroup;
  dependantForm!: FormGroup;
  forgottenPasswordForm!: FormGroup;
newMemberId:any;
  sign!: FormGroup;
 
  regType: any;
  signInState = false;
  submitted = false;
  signedIN = false;
  invalidSign = false;
  disableSubmit = true;
  SelectedChurchBranch = false;
  confirmPAss = false;
  churchBranches: any = [];
  singinMember: any;
  loading: any;
lastMemberID: any;
memberID:any; 
userdata: any;
  signUpForm:any={ memberID: '',firstName: '',middleName: '',lastName: '',phoneNumber: '',password: '',email: '',dOB: '',churchID:'', username:''};
  churchPositions: any = [];
  homecellGroup: any = [];
  biblestudyGroup: any = [];
  ministryGroup: any = [];
  selectedMinistryGroup: any[] = [];
  Titles: any = [];
  AcademicLevel: any = [];
  churchService: any = [];
  OurdataForLoop: any;
  selectedLevel: any;
  churchID: any;
  registerID:any;
  churchName: any;
   ChurchID: any;
  FirstName: any;
  MiddleName: any;
  LastName: any;
  Gender: any;
  DOB: any;
  MinistryGroup: any;
  age: any;
  MaritalStatus: any;
  ResidentialAddress: any;
  Landmark: any;
  DigitalAddress: any;
  PostalAddress: any;
  Email: any;
  PhoneNumber: any;
  MemberStatus: any;
  Employment: any;
  Occupation: any;
  Service: any;
  ChurchGroups: any;
  PositionInChurch: any;
  BibleStudyGroup: any;
  HomeCellGroup: any;
  Status: any;
  inactive_Reason: any;
  Baptized: any;
  BaptizmaDate: any;
  MarriageDate: any;
  EntryDate: any;
  EntryID: any;
  ModifiedDate: any;
  ModifiedBy: any;
  FPTemplate: any;
  Remarks: any;
  Communicant: any;
  HolySpiritBaptism: any;
  Transfered: any;
  TransferedDate: any;
  TransferedToFrom: any;
  Deceased: any;
  Dateofdeath: any;
  Officer: any;
  imageFile!: File;
  thumbnail: string = "assets/avatar.png";
  constructor(private fb: FormBuilder,
    private service: SharedService,
    private route: Router) { 

      this.service.getMemberID.subscribe(mID=> this.memberId=mID);
this.service.getchurchID.subscribe(cID => this.churchId=cID);

console.log("Parent Church ID>>>  ", parseInt(this.churchId));
console.log("Parent Member ID>>>  ", parseInt(this.memberId));
this.registerID=parseInt(this.memberId) +"";
    }


 

  ngOnInit(): void {
  
    this.getLastId() 
     this.depID = this.dep?.memberID;
     this.getMemberDetails();
            this.getData(); 
  }

  getData() {

    this.enableForm();
    this.getPosition();
    this.getHomeCellGroup();
    this.getBibleStudyGroup();
    this.getMinstryGroup();
    this.getChurchService();
    this.churchDetails();   
    this.getAcademicLevel();
    this.getTitle();
    this.setDOB();

  }

  close() {
    // this.route.
  }

  setDOB(dob?: Date) {

    var age = moment().diff(dob, 'years');
    this.dependantForm.patchValue({ age })
  }

  onFileChanged(event: any) {
    this.imageFile = event.target.files[0]
    // console.log(this.imageFile)
   
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        // this.setState({image: e.target.result});
        this.setState(e.target?.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }

  }

  setState(image: any) {
    // console.log(image)
    this.thumbnail = image;
  }


  getMemberDetails() {
    console.log("my Dep ID", this.depID);

    this.service.getMemberDetails(this.depID).subscribe(memberData => {
      memberData.dob = WebUtils.getIsoDateString(memberData.dob);
      memberData.churchGroups = memberData.churchGroups?.split(',');
      this.dependantForm.patchValue(memberData);

      if (memberData.photo != '') {
        this.thumbnail = `data:image/png;base64,${memberData.photo}`;
        let blob = this.base64ToBlob(memberData.photo);
        this.imageFile = new File([blob], 'profilePhoto', {
          type: 'image/png',
        });
      } else {
        this.thumbnail = 'assets/avatar.png';
      }
    });
  }

  async getLastId() {
    this.service.getchurchID.subscribe(cID => this.churchId=cID);
    this.mechurchID=parseInt(this.churchId)
    this.service.getMemberID.subscribe(mID=> this.memberId=mID); 
    
    var MrID = await this.service.getLastMember(this.mechurchID).toPromise();
    this.newMemberId = MrID + 1;
console.log("Dependant ID >>", this.newMemberId);
    this.signUpForm.memberID = this.newMemberId;
    this.signUpForm.churchID = parseInt(this.mechurchID);
    this.signUpForm.Username="dependant" + this.newMemberId + '@thecopdkat.org';
    this.signUpForm.email="dependant" + this.newMemberId + '@thecopdkat.org';
    this.signUpForm.firstName= '';
    this.signUpForm.middleName='';
    this.signUpForm.lastName='';
    this.signUpForm.phoneNumber='';
    this.signUpForm.dOB=''       

    await this.service.createNewMemeberID(this.signUpForm).toPromise();
  }




  async saveDependantForm(dependant: any) {    
    if (this.depID!=undefined) {
      dependant.churchGroups = dependant.churchGroups?.toString();
      const formData = new FormData();
      formData.append('file', this.imageFile, this.imageFile?.name);
      keys(dependant).forEach((k) => {
        formData.append(k, dependant[k])
      });
      this.service.updateMember(formData).subscribe(data => {
        if (data) {
          Alert.success("Record successfully saved");
          this.dependantForm.reset();

          // this.getData();
        }
      }, (error: any) => Alert.error("An error was encountered."));
    }
    else { 


      if (dependant.firstName != '' || dependant.lastName != '') {
        // this.service.getMemberID.subscribe(mID=> this.memberId=mID);
        // this.newMemberId=this.memberId;
        // console.log("new DependantID:  ", this.newMemberId);
        dependant.memberID = parseInt(this.newMemberId);
        dependant.dependantID =this.registerID;
        dependant.deceased = "No"
        dependant.Status = "Active"
        dependant.memberApproved = "true"
        dependant.entryDate = new Date;
        dependant.entryID = this.registerID + "";
        dependant.modifiedDate = new Date;
        dependant.modifiedBy = new Date;
        dependant.churchID = parseInt(this.mechurchID);
        // console.log("Dependant details:  ", dependant);

        this.signUpForm.memberID = this.newMemberId;
        this.signUpForm.churchID = parseInt(this.mechurchID);
        this.signUpForm.Username="dependant" + this.newMemberId + '@thecopdkat.org';
        this.signUpForm.email="dependant" +  this.newMemberId + '@thecopdkat.org';
        this.signUpForm.firstName= dependant.firstName;
        this.signUpForm.middleName=dependant.middleName;
        this.signUpForm.lastName=dependant.lastName;
        this.signUpForm.phoneNumber=dependant.phoneNumber;
        this.signUpForm.dOB=dependant.dob;
        console.log("Dependant details:  ", dependant);
        await this.service.signup(this.signUpForm).toPromise();
       this.service.saveMember(dependant).subscribe(data => {
        if (data) {
            Alert.success("Record successfully saved");
            this.dependantForm.reset();
                     this.route.navigate(['/updateMember']);
          }
        });
      } {
        Alert.error("An error was encountered.");
      }
    }
  }

  base64ToBlob(b64:string){
    const byteString = window.atob(b64);
   const arrayBuffer = new ArrayBuffer(byteString.length);
   const int8Array = new Uint8Array(arrayBuffer);
   for (let i = 0; i < byteString.length; i++) {
     int8Array[i] = byteString.charCodeAt(i);
   }
   const blob = new Blob([int8Array], { type: 'image/png' });    
   return blob;
  }

  churchDetails() {
    this.service.getChurchByID(parseInt(this.churchId)).subscribe(churchData => {
            this.churchName = churchData[0].Name;
      this.churchId = churchData[0].branchName;
    });
  }



  async getMinstryGroup() {
    this.service.MinistryGroup(parseInt(this.churchId)).subscribe((data) => {
      this.ministryGroup = data;
    });
  }

  async getChurchService() {
    this.service.churchService(parseInt(this.churchId)).subscribe(data => {
      this.churchService = data;
    });
  }
  async getHomeCellGroup() {
    this.service.HomeCellGroup(parseInt(this.churchId)).subscribe(data => {
      this.homecellGroup = data;
    });
  }
  async getPosition() {

    var usdata = localStorage.getItem("userLogin");
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.mechurchID = this.userdata.churchID;
    this.dependandID = this.userdata.memberID;
    this.service.getPositions(churchid).subscribe(data => {
      this.churchPositions = data;
    });
  }
  async getBibleStudyGroup() {
    this.service.BibleStudyGroup(parseInt(this.churchId)).subscribe(data => {
      this.biblestudyGroup = data;
    });
  }

  async getAcademicLevel() {
    this.service.getAcademicLevel(parseInt(this.churchId)).subscribe(data => {
      this.AcademicLevel = data;
    });
  }
  async getTitle() {
    this.service.getTitles(parseInt(this.churchId)).subscribe(data => {
      this.Titles = data;
    });
  }
  enableForm() {
    this.dependantForm = this.fb.group({
      memberID: new FormControl({ value: null, disabled: false }),
      churchID: new FormControl({ value: null, disabled: false }),
      title: new FormControl({ value: null, disabled: false }),
      firstName: new FormControl({ value: null, disabled: false }),
      middleName: new FormControl({ value: null, disabled: false }),
      lastName: new FormControl({ value: null, disabled: false }),
      gender: new FormControl({ value: null, disabled: false }),
      dob: new FormControl({ value: null, disabled: false }),
      ministryGroup: new FormControl({ value: null, disabled: false }),
      age: new FormControl({ value: null, disabled: false }),
      maritalStatus: new FormControl({ value: null, disabled: false }),
      residentialAddress: new FormControl({ value: null, disabled: false }),
      landmark: new FormControl({ value: null, disabled: false }),
      digitalAddress: new FormControl({ value: null, disabled: false }),
      postalAddress: new FormControl({ value: null, disabled: false }),
      email: new FormControl({ value: null, disabled: false }),
      phoneNumber: new FormControl({ value: null, disabled: false }),
      memberStatus: new FormControl({ value: null, disabled: false }),
      employment: new FormControl({ value: null, disabled: false }),
      occupation: new FormControl({ value: null, disabled: false }),
      educationLevel: new FormControl({ value: null, disabled: false }),
      service: new FormControl({ value: null, disabled: false }),
      churchGroups: new FormControl({ value: null, disabled: false }),
      positionInChurch: new FormControl({ value: null, disabled: false }),
      bibleStudyGroup: new FormControl({ value: null, disabled: false }),
      homeCellGroup: new FormControl({ value: null, disabled: false }),
      // Photo : new FormControl({ value: null, disabled: false }),
      status: new FormControl({ value: null, disabled: false }),
      inactive_Reason: new FormControl({ value: null, disabled: false }),
      baptized: new FormControl({ value: null, disabled: false }),
      baptizmaDate: new FormControl({ value: null, disabled: false }),
      marriageDate: new FormControl({ value: null, disabled: false }),
      entryDate: new FormControl({ value: null, disabled: false }),
      entryID: new FormControl({ value: null, disabled: false }),
      modifiedDate: new FormControl({ value: null, disabled: false }),
      modifiedBy: new FormControl({ value: null, disabled: false }),
      fPTemplate: new FormControl({ value: null, disabled: false }),
      remarks: new FormControl({ value: null, disabled: false }),
      communicant: new FormControl({ value: null, disabled: false }),
      holySpiritBaptism: new FormControl({ value: null, disabled: false }),
      transfered: new FormControl({ value: null, disabled: false }),
      transferedDate: new FormControl({ value: null, disabled: false }),
      transferedToFrom: new FormControl({ value: null, disabled: false }),
      deceased: new FormControl({ value: null, disabled: false }),
      dateofdeath: new FormControl({ value: null, disabled: false }),
      officer: new FormControl({ value: null, disabled: false }),
      dependantID: new FormControl({ value: null, disabled: false }),
      relation: new FormControl({ value: null, disabled: false }),
      memberApproved: new FormControl({ value: null, disable: false }),
    });
  }
}
