<app-top-nav></app-top-nav>
<div class="container-fluid">
  <div class="row">
    <nav id="sidebarMenu" class="col-md-4 col-lg-3 d-md-block bg-light sidebar collapse">

      <app-side-nav></app-side-nav>
    </nav>




    <main class="col-md-8 ms-sm-auto col-lg-9 px-md-4">
      <h2>All Members</h2>
      <hr>
      <div class="col-md-10 ">
        <div class="row ">
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-cherry">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0" routerLink="">Registered Members</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.allMembers}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <!-- <h5 class="card-title mb-0"> -->
                     <a routerLink="/active-members" style="cursor: pointer;">Active Members</a> 
                    <!-- </h5> -->
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.activeMembers}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-green-dark ">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">In-Active Members</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.inActiveMembers}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
        
        </div>
      </div>


      <div class="col-md-10 ">
        <div class="row ">
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-cyan">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Transfered In Members</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.transferedMemberIn}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-new1">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Transfered Out Members</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.transferdMembersOut}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-orange-dark">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Confirmed Members</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.confirmedMembers}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
         
          <!-- <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-orange-dark">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Revenue Today</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      $11.61k
                    </h2>
                  </div>
                  <div class="col-4 text-right">
                    <span>2.5% <i class="fa fa-arrow-up"></i></span>
                  </div>
                </div>
                <div class="progress mt-1 " data-height="8" style="height: 8px;">
                  <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>    
      
      <div class="col-md-10 ">
        <div class="row ">
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-new3">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Un Confirmed Members</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.unConfirmedMember}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-new2">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Adult Members</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.adultMembers}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-cherry">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Children Members</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h1 class="d-flex align-items-center mb-0" style="font-size: 38px;">
                      {{allDashboardData?.childrenMembers}}
                    </h1>
                  </div>
                  <div class="col-4 text-right">                  
                  </div>
                </div>              
              </div>
            </div>
          </div>
         
          <!-- <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-orange-dark">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Revenue Today</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      $11.61k
                    </h2>
                  </div>
                  <div class="col-4 text-right">
                    <span>2.5% <i class="fa fa-arrow-up"></i></span>
                  </div>
                </div>
                <div class="progress mt-1 " data-height="8" style="height: 8px;">
                  <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div> 


      <!-- <div class="col-md-10 ">
        <div class="row ">
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-cherry">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-shopping-cart"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">New Orders</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      3,243
                    </h2>
                  </div>
                  <div class="col-4 text-right">
                    <span>12.5% <i class="fa fa-arrow-up"></i></span>
                  </div>
                </div>
                <div class="progress mt-1 " data-height="8" style="height: 8px;">
                  <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Customers</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      15.07k
                    </h2>
                  </div>
                  <div class="col-4 text-right">
                    <span>9.23% <i class="fa fa-arrow-up"></i></span>
                  </div>
                </div>
                <div class="progress mt-1 " data-height="8" style="height: 8px;">
                  <div class="progress-bar l-bg-green" role="progressbar" data-width="25%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-green-dark">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-ticket-alt"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Ticket Resolved</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      578
                    </h2>
                  </div>
                  <div class="col-4 text-right">
                    <span>10% <i class="fa fa-arrow-up"></i></span>
                  </div>
                </div>
                <div class="progress mt-1 " data-height="8" style="height: 8px;">
                  <div class="progress-bar l-bg-orange" role="progressbar" data-width="25%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-orange-dark">
              <div class="card-statistic-3 p-4">
                <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
                <div class="mb-4">
                  <h5 class="card-title mb-0">Revenue Today</h5>
                </div>
                <div class="row align-items-center mb-2 d-flex">
                  <div class="col-8">
                    <h2 class="d-flex align-items-center mb-0">
                      $11.61k
                    </h2>
                  </div>
                  <div class="col-4 text-right">
                    <span>2.5% <i class="fa fa-arrow-up"></i></span>
                  </div>
                </div>
                <div class="progress mt-1 " data-height="8" style="height: 8px;">
                  <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <hr>
   
     
      <!-- <div class="table-responsive">
        <div class="container px-4 py-5" id="custom-cards">
          <h2 class="pb-2 border-bottom">Dashboard</h2>

          <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-dark  text-center">Registered Members</h5>
                  <h2 class="text-dark text-center">{{allMembers.length}}</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-dark text-center">Active Members</h5>
                  <h2 class="text-dark text-center">{{allActive.length}}</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-center">In-Active Members</h5>
                  <h2 class="text-dark text-center">{{allInActive.length}}</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-dark  text-center">Transfered In</h5>
                  <h2 class="text-dark text-center">{{transferedIn.length}}</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-dark text-center">Transfered Out</h5>
                  <h2 class="text-dark text-center">{{transferedOut.length}}</h2>
                </div>
              </div>
            </div>


            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-center">UnConfirmed Members</h5>
                  <h2 class="text-dark text-center">{{unConfirmedMember.length}}</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-dark text-center">Deceased</h5>
                  <h2 class="text-dark text-center">{{transferedOut.length}}</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-dark text-center">Adult Members</h5>
                  <h2 class="text-dark text-center">{{transferedOut.length}}</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-sm">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-3">
                  <h5 class="pt-2 mt-1 mb-2 display-0 lh-1 fw-light text-dark text-center">Childrens</h5>
                  <h2 class="text-dark text-center">{{transferedOut.length}}</h2>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> -->
    </main>
  </div>
</div>