<div class="container">
    <nav class="navbar">
      <div class="nav_icon" (click)="addToggle()">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </div>
      <div class="navbar__left">
        <!-- <a href="#">Subscribers</a>
        <a href="#">Video Management</a> -->
        <a class="active_link" href="#">Admin</a>
      </div>
      <div class="navbar__right">
        <a href="#">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a>
        <!-- <a href="#">
          <i class="fa fa-clock-o" aria-hidden="true"></i>
        </a> -->
        <a href="#">
           <img width="30" src="https://i.pravatar.cc/300">
          
        </a>
      </div>
    </nav>
    <main>
      <div class="main__container">
        <!-- MAIN TITLE STARTS HERE -->
        <div class="main__title">
          <div class="main__greeting">
            <!-- <h1>Hello Admin</h1> -->
            <h4>Welcome to your admin dashboard</h4>
          </div>
        </div>
        <!-- MAIN TITLE ENDS HERE -->
        <!-- MAIN CARDS STARTS HERE -->
        <div class="main__cards">
          <div class="card">
            <!-- <i
              class="fa fa-user-o fa-2x text-lightblue"
              aria-hidden="true"
            ></i> -->
            <div class="card_inner">
               
              <p class="text-primary-p"><a style="text-decoration:none" routerLink="/members-list">Approved Members</a> </p>
              <span class="font-bold text-title">   {{allDashboardData?.registerdMembers}}</span>
            </div>
          </div>
          <div class="card">
            <!-- <i class="fa fa-calendar fa-2x text-red" aria-hidden="true"></i> -->
            <div class="card_inner">
              <!-- <p class="text-primary-p"> -->
                <a style="text-decoration:none" routerLink="/active-members">Active Members</a>
              <!-- </p> -->
              <span class="font-bold text-title">{{allDashboardData?.activeMembers}}</span>
            </div>
          </div>
          <div class="card">
            <!-- <i
              class="fa fa-video-camera fa-2x text-yellow"
              aria-hidden="true"
            ></i> -->
            <div class="card_inner">
              <!-- -->
             
              <p class="text-primary-p"><a style="text-decoration:none" routerLink="/members-in-active">InActive Members</a></p>
              <span class="font-bold text-title">{{allDashboardData?.inActiveMembers}}</span>
            </div>
          </div>
          <div class="card">
            <!-- <i
              class="fa fa-thumbs-up fa-2x text-green"
              aria-hidden="true"
            ></i> -->
            <div class="card_inner">
              <p class="text-primary-p"><a style="text-decoration:none" routerLink="/member-transferd">Transfered In Members</a></p>

              <!-- <p class="text-primary-p"></p> -->
              <span class="font-bold text-title"> {{allDashboardData?.transferedMemberIn}}</span>
            </div>
          </div>
        </div>
       
        <div class="main__cards">
          <div class="card">
            <!-- <i
              class="fa fa-user-o fa-2x text-lightblue"
              aria-hidden="true"
            ></i> -->
            <div class="card_inner">
              <!-- <p class="text-primary-p">Transfered Out </p> -->
              <p class="text-primary-p"><a style="text-decoration:none" routerLink="/members-confirmed">Transfered Out</a></p>

              <span class="font-bold text-title">   {{allDashboardData?.transferdMembersOut}}</span>
            </div>
          </div>
          <!-- <div class="card">
            <div class="card_inner">
              <
              <div class="card_inner">

                <p class="text-primary-p"><a style="text-decoration:none" routerLink="/members-confirmed">Confirmed Members</a></p>

                <span class="font-bold text-title">  {{allDashboardData?.confirmedMembers}}</span>
              </div>
            </div>
          </div> -->
          <div class="card">
            <!-- <i
              class="fa fa-video-camera fa-2x text-yellow"
              aria-hidden="true"
            ></i> -->
            <div class="card_inner">
              <!-- <p class="text-primary-p">Not confirmed Members</p> -->

              <p class="text-primary-p"><a style="text-decoration:none" routerLink="/unconfirmed-members">Unconfirmed Members</a></p>

              <span class="font-bold text-title">{{allDashboardData?.unConfirmedMembers}}</span>
            </div>
          </div>
          <div class="card">
            <!-- <i
              class="fa fa-thumbs-up fa-2x text-green"    unconfirmed-members
              aria-hidden="true"
            ></i> -->
            <div class="card_inner">
              <!-- <p class="text-primary-p">Adult Members</p> -->

              <p class="text-primary-p"><a style="text-decoration:none" routerLink="/adult-members">Adult Members</a></p>
              <span class="font-bold text-title"> {{allDashboardData?.adultMembers}}</span>
            </div>
          </div>

          <div class="card">
            <!-- <i
              class="fa fa-thumbs-up fa-2x text-green"
              aria-hidden="true"
            ></i> -->
            <div class="card_inner">
              <!-- <p class="text-primary-p">Children Members</p> -->

              <p class="text-primary-p"><a style="text-decoration:none" routerLink="/children-members">Children Members</a></p>


              <span class="font-bold text-title"> {{allDashboardData?.childrenMembers}}</span>
            </div>
          </div>
        </div>
    
      </div>
    </main>
    <div id="sidebar" [ngClass]="status ? 'sidebar_responsive' : ''">
      <div class="sidebar__title">
        <div class="sidebar__img">
          <h1>Admin</h1>
        </div>
        <i
        (click)="addToggle()"
          class="fa fa-times"
          id="sidebarIcon"
          aria-hidden="true"
        ></i>
      </div>
     < <div class="sidebar__menu">
       <app-side-nav></app-side-nav>
      </div>
    </div>
  </div>