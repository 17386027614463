<div class="container register-form">
  <div class="form">
    <div class="note">
      <h4 class="d-flex justify-content-center" style="color: whitesmoke;">
        {{ churchId }}
      </h4>

      <ng-container *ngIf="checkApprovedMember; else elseTemplate">
        <h4 class="d-flex justify-content-center">
          Membership Information Update - Approved
        </h4>
      </ng-container>
      <ng-template #elseTemplate>
        <h4 class="d-flex justify-content-center">
          Membership Information Update - Not Approved
        </h4>
      </ng-template>

      <div></div>
    </div>

    <div class="form-content">
      <div class="input-group flex-nowrap">
        <span class="input-group-text" id="addon-wrapping">Member ID</span>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="memberID"
          type="text"
          class="form-control"
          id="MemberID"
          [attr.disabled]="true ? '' : null"
          aria-label="Username"
          aria-describedby="addon-wrapping"
        />

        <button
          class="btn btn-success"
          type="submit"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop2"
        >
          Change Password
        </button>

        <button
          class="btn btn-danger"
          type="submit"
          id="article-update-button"
          (click)="logout()"
        >
          Logout
        </button>
      </div>

      <hr />
      <div *ngIf="loadSpinner">
        <app-spinner-loader></app-spinner-loader>
      </div>
      <form
        [formGroup]="memberForm"
        (ngSubmit)="updateMemberInfo(memberForm.value)"
        class="row g-3"
        *ngIf="getMemberDetails"
      >
        <div class="col-md-6">
          <label for="Title" class="form-label label">Title </label>
          <select
            class="form-select"
            formControlName="title"
            required
            id="Title"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected></option>
            <option *ngFor="let item of Titles" [value]="item['name']">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-md-6">
          <label for="firstname" class="form-label label">First Name</label>
          <input
            formControlName="firstName"
            type="text"
            class="form-control"
            id="firstName"
            [readonly]="!checkApprovedMember"
          />
        </div>
        <div class="col-md-6">
          <label for="middlename" class="form-label label">Middle Name</label>
          <input
            formControlName="middleName"
            type="text"
            class="form-control"
            id="MiddleName"
            [readonly]="!checkApprovedMember"
          />
        </div>

        <div class="col-md-6">
          <label for="lastname" class="form-label label">Last Name</label>
          <input
            formControlName="lastName"
            type="text"
            class="form-control"
            id="lastname"
            [readonly]="!checkApprovedMember"
          />
        </div>

        <div class="col-md-6">
          <label for="phoneNumber" class="form-label label">Phone Number</label>
          <input
            formControlName="phoneNumber"
            type="text"
            class="form-control"
            id="PhoneNumber"
            [readonly]="!checkApprovedMember"
          />
        </div>
        <div class="col-md-6">
          <label for="email" class="form-label label">Email</label>
          <input
            formControlName="email"
            type="text"
            class="form-control"
            id="Email"
          />
        </div>

        <div class="col-md-6">
          <label class="form-label label"> Date of Birth</label>
          <input
            formControlName="dob"
            type="date"
            class="form-control"
            id="dob"
            (change)="setDOB(memberForm.value.dob)"
            [attr.disabled]="!checkApprovedMember ? true : null"
          />
        </div>

        <div class="col-md-6">
          <label for="gender" class="form-label label">Gender</label>
          <select
            class="form-select"
            formControlName="gender"
            required
            id="gender"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected>Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div class="col-md-6">
          <label for="age" class="form-label label">Age</label>
          <input
            formControlName="age"
            type="number"
            class="form-control"
            id="age"
            [readonly]="!checkApprovedMember"
          />
        </div>

        <div class="col-md-6">
          <label for="maritalStatus" class="form-label label"
            >Marital Status</label
          >
          <select
            class="form-select"
            formControlName="maritalStatus"
            required
            id="maritalStatus"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected></option>
            <option value="Married">Married</option>
            <option value="Single">Single</option>
            <option value="Divorced">Divorced</option>
            <option value="Divorced">Widowed</option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="maritalStatus" class="form-label label">Employment</label>
          <select
            class="form-select"
            formControlName="employment"
            required
            id="maritalStatus"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected>Select Employment</option>
            <option value="Yes">Employed</option>
            <option value="Yes">Self-Employed</option>
            <option value="Yes">Unemployed</option>
            <option value="Yes">Student</option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="occupation" class="form-label label">Occupation</label>
          <input
            formControlName="occupation"
            type="text"
            class="form-control"
            id="occupation"
            [readonly]="!checkApprovedMember"
          />
        </div>

        <div class="col-md-6">
          <label for="residentialAddress" class="form-label label"
            >Residential Address</label
          >
          <input
            formControlName="residentialAddress"
            type="text"
            class="form-control"
            id="residentialAddress"
            [readonly]="!checkApprovedMember"
          />
        </div>
        <div class="col-md-6">
          <label for="landmark" class="form-label label">Land Mark</label>
          <input
            formControlName="landmark"
            type="text"
            class="form-control"
            id="landmark"
            [readonly]="!checkApprovedMember"
          />
        </div>

        <div class="col-md-6">
          <label for="digitalAddress" class="form-label label"
            >Digital Address</label
          >
          <input
            formControlName="digitalAddress"
            type="text"
            class="form-control"
            id="digitalAddress"
            [readonly]="!checkApprovedMember"
          />
        </div>
        <div class="col-md-6">
          <label for="postalAddress" class="form-label label"
            >Postal Address</label
          >
          <input
            formControlName="postalAddress"
            type="text"
            class="form-control"
            id="postalAddress"
            [readonly]="!checkApprovedMember"
          />
        </div>

        <div class="col-md-6">
          <label for="educationLevel" class="form-label label"
            >Highest Academic Level</label
          >
          <select
            class="form-select"
            formControlName="educationLevel"
            required
            id="educationLevel"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected></option>
            <option
              *ngFor="let item of AcademicLevel"
              [value]="item['educationLevel']"
            >
              {{ item.educationLevel }}
            </option>
          </select>
        </div>

        <div class="col-md-6">
          <label for="Officer" class="form-label label"
            >Are You a Church Officer?</label
          >
          <select
            class="form-select"
            formControlName="officer"
            required
            id="officer"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected>Select Officer</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div class="col-md-6">
          <label class="form-label label">Position in Church</label>
          <select
            class="form-select"
            formControlName="positionInChurch"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value=""></option>

            <option
              *ngFor="let item of churchPositions"
              [value]="item['positions']"
            >
              {{ item.positions }}
            </option>
          </select>
        </div>

        <div class="col-md-6">
          <label for="bibleStudyGroup" class="form-label label"
            >Bible Study Group</label
          >
          <select
            class="form-select"
            formControlName="bibleStudyGroup"
            required
            id="bibleStudyGroup"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected></option>
            <option *ngFor="let item of biblestudyGroup" [value]="item['name']">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="homeCellGroup" class="form-label label"
            >Home Cell Group</label
          >
          <select
            class="form-select"
            formControlName="homeCellGroup"
            required
            id="homeCellGroup"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected></option>
            <option *ngFor="let item of homecellGroup" [value]="item['name']">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-md-6">
          <label for="deceased" class="form-label label">
            Select Ministries and Groups You belong to:
          </label>
          <ng-select
            [items]="ministryGroup"
            [multiple]="true"
            bindLabel="ministry"
            bindValue="ministry"
            placeholder="Select ministry group"
            [closeOnSelect]="false"
            [hideSelected]="true"
            formControlName="churchGroups"
          >
          </ng-select>
        </div>

        <div class="col-md-6">
          <label for="service" class="form-label label">Church Service</label>
          <select
            class="form-select"
            formControlName="service"
            required
            id="service"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected></option>
            <option *ngFor="let item of churchService" [value]="item['name']">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-md-6">
          <label for="baptized" class="form-label label">Baptized</label>
          <select
            class="form-select"
            formControlName="baptized"
            required
            id="baptized"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected>Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="baptizDate" class="form-label label">Baptizma Date</label>
          <input
            formControlName="baptizmaDate"
            type="date"
            class="form-control"
            id="baptizDate"
            [readonly]="!checkApprovedMember"
          />
        </div>
        <div class="col-md-6">
          <label for="marriageDate" class="form-label label"
            >Marriage Date</label
          >
          <input
            formControlName="marriageDate"
            type="date"
            class="form-control"
            id="marriageDate"
            [readonly]="!checkApprovedMember"
          />
        </div>
        <div class="col-md-6">
          <label for="communicant" class="form-label label">Communicant</label>
          <select
            class="form-select"
            formControlName="communicant"
            required
            id="communicant"
          >
            <option value="" disabled selected>Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div class="col-md-6">
          <label for="holySpiritBaptism" class="form-label label"
            >Holy Spirit Baptism</label
          >
          <select
            class="form-select"
            formControlName="holySpiritBaptism"
            required
            id="holySpiritBaptism"
            [attr.disabled]="!checkApprovedMember ? true : null"
          >
            <option value="" disabled selected>Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="status" class="form-label label">Status</label>
          <select
            class="form-select"
            formControlName="status"
            required
            id="status"
            [attr.disabled]="true ? '' : null"
          >
            <option value="" disabled selected>Select</option>
            <option value="Active">Active</option>
            <option value="Inactive">In Active</option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inactive_Reason" class="form-label label"
            >Inactive Reason</label
          >
          <input
            formControlName="inactive_Reason"
            [disabled]="true"
            type="text"
            class="form-control"
            id="inactive_Reason"
            [attr.disabled]="true ? '' : null"
            [readonly]="!checkApprovedMember"
          />
        </div>
        <div class="col-md-6">
          <label for="transfered" class="form-label label">Transfered </label>
          <select
            class="form-select"
            formControlName="transfered"
            required
            id="transfered"
            [attr.disabled]="true ? '' : null"
          >
            <option value="" disabled selected>Select</option>
            <option value="TransferedTo">TransferedTo</option>
            <option value="TransferedFrom">TransferedFrom</option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="transferedDate" class="form-label label"
            >Transfered Date</label
          >
          <input
            formControlName="transferedDate"
            type="date"
            class="form-control"
            id="transferedDate"
            [attr.disabled]="!checkApprovedMember ? true : null"
            [readonly]="!checkApprovedMember"
          />
        </div>
        <div class="col-md-6">
          <label for="transferedToFrom" class="form-label label"
            >Transfered To/From</label
          >
          <input
            formControlName="transferedToFrom"
            type="text"
            class="form-control"
            id="transferedToFrom"
            [attr.disabled]="true ? '' : null"
          />
        </div>

        <div class="col-md-6">
          <label for="deceased" class="form-label label">Deceased</label>
          <select
            class="form-select"
            formControlName="deceased"
            required
            id="deceased"
             [attr.disabled]="true ? '' : null"
          >
            <option value="" disabled selected>Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div class="mb-3">
          <input
            type="file"
            class="form-control"
            aria-label="file example"
            (change)="onFileChanged($event)"
            #fileInpu
            required
            [readonly]="!checkApprovedMember"
          />
          <div class="invalid-feedback">Add Photo</div>
        </div>
        <div class="col-md-6">
          <img
            [src]="thumbnail"
            class="img-thumbnail"
            alt="image"
            width="120"
            height="120"
          />
        </div>

        <div class="input-group flex-nowrap col-md-6">
          <button
            class="btn btn-sml btn-success save float-right"
            type="submit"
          >
            Save Changes
          </button>
          <!-- <i *ngIf="btnSpinner" class="fa fa-spinner fa-spin" style="margin-left: 15px;"></i> -->
          <!-- <i *ngIf="btnSpinner" class="fa fa-spinner" aria-hidden="true"></i> -->
          <!-- <i class="fa-solid fa-spinner mr-2" style="margin-left: 15px;"></i> -->
          <!-- <img src="../../assets/spin.gif"> -->
        </div>
      </form>
    </div>

    <!-- ------------------------modal Starts here---------------------  -->
    <div class="form-content">
      <div class="dependant">
        Click or Tap "Add Dependants" to Register All your dependants who can
        not register on their own for now. You can later give them, their
        membership ID, for them to create their own login Account to update that
        information.
      </div>

      <button
        type="button"
        class="btn btn-warning"
        data-bs-toggle="modal"
        (click)="addDependant()"
        data-bs-target="#staticBackdrop"
      >
        Add Dependants
      </button>

      <table class="table table-striped">
        <thead>
          <tr class="bg-light clearfix">
            <th>ID</th>
            <th>Name</th>
            <th>Relation</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of DependantList">
            <td>{{ item.memberID }}</td>
            <td>{{ item.firstName }} {{ item.lastName }}</td>
            <td>{{ item.relation }}</td>
            <td>
              <button
                type="button"
                class="btn btn-light mr-1"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                (click)="editClick(item)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Dependant Registration
              </h5>
              <button
                class="btn btn-primary btn-close="
                data-bs-dismiss="modal"
                type="submit"
                (click)="closeModal()"
                id="article-update-button"
              >
                Exit
              </button>
            </div>

            <div class="modal-body">
              <add-dependant [dep]="item" *ngIf="activateModal"></add-dependant>
            </div>

            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Dependant Registration
              </h5>
              <button
                class="btn btn-primary btn-close="
                data-bs-dismiss="modal"
                type="submit"
                (click)="closeModal1()"
                id="article-update-button"
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------------- Modal ends here ---------------------  -->
  </div>

  <!-- Button trigger modal -->

  <!-- <button class="btn btn-success" type="submit" data-bs-toggle="modal" data-bs-target="#staticBackdrop2"
>Change Password</button> -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="staticBackdrop2"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title fs-5" id="staticBackdropLabel">
            Change Password
          </h3>
          <br /><br />
          <p style="margin: 55px 0 6px -160px;">
            Please chnage your password and click on submit button.
          </p>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Understood</button>
        </div> -->
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="old-password" class="col-form-label"
                    >Current Password <span class="asterics">*</span></label
                  >
                </div>
                <div class="mb-3">
                  <label for="new-password" class="col-form-label"
                    >New Password <span class="asterics">*</span></label
                  >
                </div>
                <div class="mb-3">
                  <label for="confirm-new-password" class="col-form-label"
                    >Re-type Password <span class="asterics">*</span></label
                  >
                </div>
              </div>
              <div class="col-md-8">
                <div class="mb-3">
                  <div class="input-group mb-3">
                    <input
                      type="password"
                      class="form-control"
                      aria-label="Recipient's username"
                      placeholder="current password here"
                      aria-describedby="basic-addon2"
                      name="currentPassword"
                      [(ngModel)]="currentPassword"
                    />
                    <div
                      *ngIf="currentPassword !== cpdPass && currentPassword !== ''"
                      class="error-message"
                    >
                      ❌
                    </div>
                    <div class="input-group-append">
                      <span
                        *ngIf="currentPassword == cpdPass"
                        class="input-group-text"
                        id="basic-addon2"
                        >✔️</span
                      >
                    </div>
                  
                  </div>
                </div>
                <!-- <span  *ngIf="!currentPSD" class="input-group-text" id="basic-addon2"></span>s -->
                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="newPassword"
                    placeholder="new password here"
                    name="newPassword"
                    [(ngModel)]="newPassword"
                    [minlength]="6"
                    required
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="confirmPassword"
                    placeholder="confirm new password"
                    name="confirmPassword"
                    [(ngModel)]="confirmPassword"
                    [minlength]="6"
                    required
                  />
                </div>

                
                <div
                  *ngIf="
                newPassword !== confirmPassword &&
                newPassword !== '' && confirmPassword !== ''
              "
                  class="error-message"
                >
                  New password do not match
                </div>
              </div>
            </div>

            <p [ngClass]="passwordMsg ? 'text-success' : 'text-danger'">
              {{ passwordmsg }}
            </p>
            <h4 class="error-message">{{ passwordChanged }}</h4>
          </form>
        </div>
        <div class="modal-footer">
          <div *ngIf="processingPassword;">
            <div class="spinner-border text-secondary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div>
            <button
              type="button"
              class="btn btn-primary"
              (click)="changePassword()"
            >
              Submit
            </button>
          </div>

       
          <!-- <button
            type="button"
            class="btn btn-danger"
            (click)="(confirmCurrentPassword)"
            (click)="confirmCurrentPassword()"
          >
            Clear
          </button> -->
        </div>
      </div>
    </div>
  </div>
</div>
