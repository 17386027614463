 <div class="sidebar__menu">
  <div class="sidebar__link active_menu_link">
    <i class="fa fa-home"></i>
    <a routerLink="/MainAdminDashboard">Dashboard</a>
  </div>
  <!-- <h2>MNG</h2> -->
  <div class="sidebar__link">
    <i class="fa fa-user-secret" aria-hidden="true"></i>
    <a routerLink="/members-list">Approved Members</a>
    
  </div>
  <div class="sidebar__link">
    <i class="fa fa-building-o"></i>
    <a routerLink="/active-members">Active members</a>
  </div>
  <div class="sidebar__link">
    <i class="fa fa-wrench"></i>
    <a routerLink="/members-in-active">In-Active members</a>
  </div>
  <!-- <div class="sidebar__link">
    <i class="fa fa-archive"></i>
    <a routerLink="/members-in-active">Members Active</a>
  </div> -->

  <!-- <div class="sidebar__link">
    <i class="fa fa-sign-out"></i>
    <a routerLink="/members-confirmed">Confirmed members</a>
  </div> -->

  <div class="sidebar__link">
    <i class="fa fa-handshake-o"></i>
    <a  routerLink="/unconfirmed-members">Unconfirmed Members</a>
  </div>

  <div class="sidebar__link">
    <i class="fa fa-question"></i>
    <a routerLink="/member-transferd">Tranfered In Members</a> 
  </div>
  <div class="sidebar__link">
    <i class="fa fa-calendar-check-o"></i>
    <a routerLink="/member-transferd-out">Tranfered Out  </a>
  </div>
 
  <div class="sidebar__link">
    <i class="fa fa-files-o"></i>
    <a  routerLink="/adult-members">Adult members</a>
  </div>
 
  <div class="sidebar__link">
    <i class="fa fa-files-o"></i>
    <a  routerLink="/children-members">Children members</a>
  </div>
  <div class="sidebar__link">
    <i class="fa fa-files-o"></i>
    <a  routerLink="/member-deceased">Deceased members</a>
  </div>
  <!-- <h2>PAYROLL</h2>
  <div class="sidebar__link">
    <i class="fa fa-money"></i>
    <a href="#">Payroll</a>
  </div>-->
  <div class="sidebar__link" *ngIf="level==1">
    <i class="fa fa-briefcase"></i>
    <a routerLink="/admin-users">Add Admin</a>
  </div> 

  
  <div class="sidebar__logout">
    <i class="fa fa-power-off"></i>
    <a href="#">Log out</a>
  </div>
</div>