<div class="app">
    <br><br>
    <div class="container  text-center">
        <!-- <h2 style="font-weight: 800;        color: #FFD200;" class="d-flex justify-content-center">{{branchName}}</h2> -->
        <!-- <h2>The Church of Pentecost, Teshie</h2> -->
        <h1 class="d-flex justify-content-center subTitle" style="color:#fff ; font-weight:400;font-weight: 600;
        /* font-style: normal; */
        font-size: 12px; margin-top: auto;">Admin Membership Information </h1>
    </div>

    <div class="row no-gutters">
        <div class="col-md-6 mx-auto p-0">
            <!-- <div class="card"> -->
            <div class="login-box">
                <div class="login-snip">
                    <div *ngIf="!toast">
                        <div class="alert alert-dark fade show" role="alert">
                            <strong> </strong> <span class="text-danger">{{loginError}}</span>
                        </div>
                       
                    </div>
                    <input id="tab-1" type="radio" name="tab" class="sign-in" checked>
                    <label for="tab-1" class="tab">Login </label>

                    <input id="tab-2" type="radio" name="tab" class="sign-up" (click)="hideRecovery()">
                    <label for="tab-2" class="tab">  </label>
                    
                    <div class="login-space">

                        <form [formGroup]="signInForm" (ngSubmit)="AdminSignIn(signInForm.value)"
                            class="ion-no-padding ion-no-margin">
                            <div class="login" *ngIf="!recoverPass">
                                <br>
                                <div class="group">
                                    <div class="validate" *ngIf="invalidSign"> Invalid Username or Password, Please, Try
                                        Again</div>
                                </div>

                                <div class="group">
                                    <input formControlName="username" name="username" type="text" class="input"
                                        placeholder="Enter your Username" style="color: black;">
                                    <!-- <div *ngIf="signedIN && s.Username.errors">
                                        <div class="validate" *ngIf="s.Username.errors.required">Username Is Required
                                        </div>
                                    </div>
                                    <div *ngIf=" s.username.errors">
                                        <div class="validate" *ngIf="s.username.errors.required">
                                            <p>Username Is Required</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="group"> <input formControlName="password" name="password" type="password"
                                        class="input" placeholder="Enter your password" style="color: black;">
                                    <!--    <div *ngIf="signedIN && s.password.errors">
                                        <div class="validate" *ngIf="s.password.errors.required">Password Is Required
                                        </div>
                                     <div class="validate" *ngIf="s.Password.errors.minlength">Password at Least 6</div> 
                                    </div>-->
                                </div>

                                <div class="group"> <input name="check" type="checkbox" class="check" checked> <label
                                        for="check"><span class="icon"></span> Keep me
                                        Signed in</label> </div>

                                <div class="group">
                                     <button class="button" type="submit" value="Sign In">
                                        Login <span *ngIf="loading" style="width: 1.5rem; height: 1.5rem;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <div *ngIf="signInState!=false" routerLink="dashboard">
                                    </div>
                                     </button>
                                </div>

                                <div class="ahef">
                                    <a href="javascript:void(0)" (click)="recoverPass=true"
                                        style="font-style:italic;color: #fff; text-align: center !important"
                                        class="font-12">Recover
                                        Forgotten Password, through email</a>
                                </div>
                                

                                <div class=" row d-flex justify-content-center align-content-center " style="margin: 30px auto;">
                                    <button class=" btn btn-info btn-lg text-center" routerLink="/register">Member 
                                        Login </button>

                                </div>

                            </div>


                            <div class="group" *ngIf="recoverPass">
                                <input [(ngModel)]="recoveryEmail" name="recpass" type="text"
                                    [ngModelOptions]="{standalone: true}" class="input   mb-3"
                                    placeholder="Enter your Email" style="color: black;">
                            </div>

                            <div class="group" *ngIf="recoverPass">
                                <input type="button" class="button" value="Recover Password"
                                    (click)="recoverPassword()">
                            </div>

                            <div class="group" *ngIf="recoverPass">
                                <input type="button" value="<< Back to Sign In" (click)="backToSignIn()">
                            </div>

                        </form>


                        <form [formGroup]="signUpForm" (ngSubmit)="signUp(signUpForm.value)"
                            class="ion-no-padding ion-no-margin">
                            <!-- <div class="sign-up-form">
                                <div class="group font-12" style="font-size: 12px;">
                                    Select registration type:
                                    <select class="form-select" [(ngModel)]="regType" name="registration"
                                        style="border-radius: 25px !important" [ngModelOptions]="{standalone: true}"
                                        (change)="selectedRegType(regType)">
                                        <option value="New">New Member Registration</option>
                                        <option value="Existing">Register with existing MemberID, DOB & Firstname
                                        </option>
                                    </select>
                                
                                </div>

                                <div class="group" *ngIf="regType=='Existing'"> <input formControlName="memberID"
                                        name="memberID" type="text" class="input" placeholder="Enter Existing Member ID"
                                        style="color: black;"> </div>


                                <div class="group">
                                    <label
                                        style="color:lightgrey; font-family: Arial, Helvetica, sans-serif !important; font-size:12px">
                                        Select / Enter date of birth: </label>
                                    <input formControlName="dOB" name="dOB" type="date" class="input"
                                        placeholder="Date of birth" style="color: black;">
                                    <div *ngIf="submitted && f.dOB.errors">
                                        <div class="validate" *ngIf="f.dOB.errors.required">Date of Birth Is Required
                                        </div>
                                    </div>
                                </div>

                                <div class="group"> <input formControlName="firstName" name="firstName" type="text"
                                        class="input" placeholder="First name" style="color: black;">
                                    <div *ngIf="submitted && f.firstName.errors">
                                        <div class="validate" *ngIf="f.firstName.errors.required">first Name Is Required
                                        </div>
                                    </div>
                                </div>

                                <div class="group" *ngIf="regType=='Existing'"> <input type="button" class="button"
                                        value="Search" (click)="searchMember(signUpForm.value)">
                                </div>

                                <div class="group"> <input formControlName="middleName" name="middleName" type="text"
                                        class="input" placeholder="Middle name" style="color: black;"> </div>

                                <div class="group"> <input formControlName="lastName" name="lastName" type="text"
                                        class="input" placeholder="Last name" style="color: black;">
                                    <div *ngIf="submitted && f.lastName.errors">
                                        <div class="validate" *ngIf="f.lastName.errors.required">Last name Is Required
                                        </div>
                                    </div>
                                </div>


                                <div class="group"> <input formControlName="phoneNumber" name="phoneNumber" type="text"
                                        class="input" placeholder="Mobile Phone Number" style="color: black;">
                                    <div *ngIf="submitted && f.phoneNumber.errors">
                                        <div class="validate" *ngIf="f.phoneNumber.errors.required">Mobile Phone Number
                                            Is Required</div>
                                    </div>
                                </div>

                                <div class="group"> <input formControlName="email" name="email" type="email"
                                        class="input" placeholder="Email address" style="color: black;">
                                    <div *ngIf="submitted && f.email.errors">
                                        <div class="validate" *ngIf="f.email.errors.required">Email Is Required</div>
                                    </div>
                                </div>
                                <div class="group">
                                    <input formControlName="username" name="username" type="text" class="input"
                                        placeholder="Enter Username / a Phone number" style="color: black;">
                                    <div *ngIf="submitted && f.username.errors">
                                        <div class="validate" *ngIf="f.username.errors.required">Username Is Required
                                        </div>
                                    </div>
                                </div>
                                <div class="group"> <input formControlName="password" name="password" type="password"
                                        class="input" placeholder="Password (6 Charaters, Minimum)"
                                        style="color: black;">
                                    <div *ngIf="submitted && f.password.errors">
                                        <div class="validate" *ngIf="f.password.errors.required">Password Is Required
                                        </div>
                                     </div>
                                </div>

                                <div class="group"> <input name="confirmPassword" formControlName="confirmPassword"
                                        type="password" class="input"
                                        placeholder="Repeat Password (6 Charaters, Minimum)" style="color: black;">
                                    <div *ngIf="submitted && f.confirmPassword.errors">
                                        <div class="validate" *ngIf="f.confirmPassword.errors.required">Confirm Password
                                            Is Required</div>
                                        <div class="validate" *ngIf="f.confirmPassword.errors.MustMatch">Passwords Do
                                            Not Match</div>
                                    </div>
                                </div>
                                <br>
                                <div class="group"> <span class="font-12">Select church branch</span>
                                    <select class="form-select" formControlName="churchID" name="churchID"
                                        style="border-radius: 25px !important"
                                        (change)="selectedbranch(signUpForm.value.churchID)" #churchBranchSelect>
                                        <option value="" disabled selected> </option>
                                        <option [value]="branches['churchID']" *ngFor="let branches of churchBranches">
                                            {{branches.branchName}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.churchID.errors">
                                        <div class="validate" *ngIf="f.churchID.errors.required">Church Branch Is
                                            Required</div>
                                    </div>
                                </div>

                                <div class="group" *ngIf="SelectedChurchBranch"> <input type="submit" class="button"
                                        value="Sign Up"> </div>


                                <div class="hr"></div>

                            </div> -->
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col text-center">
            <small class="d-block  text-muted">&copy; {{getDate}} - DKAWC</small>
        </div>

    </div>
    
</div>