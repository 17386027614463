import { keyframes } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../shared.service';
import { WebUtils } from '../shared/web-utils';
import { keys } from 'underscore';
import * as moment from 'moment';
import { Alert } from '../shared/message-helper';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { LoaderService } from '../loader-service.service';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-member-update',
  templateUrl: './member-update.component.html',
  styleUrls: ['./member-update.component.css'],
})
export class MemberUpdateComponent implements OnInit {
  memberId:any;
  churchId:any;
  userdata: any;
  memberForm!: FormGroup;
  churchPositions: any = [];
  homecellGroup: any = [];
  biblestudyGroup: any = [];
  ministryGroup: any = [];
  selectedMinistryGroup: any[] = [];
  churchService: any = [];
  Titles: any = [];
  AcademicLevel: any = [];
  DependantList: any[] = [];
  OurdataForLoop: any;
  selectedLevel: any;
  churchID: any;
  churchName: any;
  memberID: any;
  MemberID: any;
  ChurchID: any;
   meMemberID: any;
  meChurchID: any;
  Title: any;
  FirstName: any;
  MiddleName: any;
  LastName: any;
  Gender: any;
  DOB: any;
  MinistryGroup: any;
  Age: any;
  MaritalStatus: any;
  ResidentialAddress: any;
  Landmark: any;
  DigitalAddress: any;
  PostalAddress: any;
  Email: any;
  PhoneNumber: any;
  MemberStatus: any;
  Employment: any;
  Occupation: any;
  Service: any;
  ChurchGroups: any;
  PositionInChurch: any;
  BibleStudyGroup: any;
  HomeCellGroup: any;
  // Photo : any;
  Status: any;
  inactive_Reason: any;
  Baptized: any;
  BaptizmaDate: any;
  MarriageDate: any;
  EntryDate: any;
  EntryID: any;
  ModifiedDate: any;
  ModifiedBy: any;
  FPTemplate: any;
  Remarks: any;
  Communicant: any;
  HolySpiritBaptism: any;
  Transfered: any;
  TransferedDate: any;
  TransferedToFrom: any;
  Deceased: any;
  Dateofdeath: any;
  Officer: any;
  DependantID: any;
  Relation: any;
  imageFile!: File;
  thumbnail: string = 'assets/avatar.png';
  loadSpinner: any = false;
  btnSpinner: any = false;
  clicked = false;
  MemberApproved: any;

  checkApprovedMember: boolean = false;

  currentPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  errorMessage: string | undefined;
  passwordmsg: string = '';
  userGlobal: any;
  ccpdReturn: any;
  ccpdSplit: any;

  passwordChanged: any;
  processingPassword: boolean =false;

  // newPassword!: FormControl;
  // confirmPassword!: FormControl;

  actionMethod(event: any) {
    event.target.disabled = true;
    event.target.disabled = false;
  }

  ActivateEditDependant: boolean = false;
  editDep: any;

  @Input() set disableControl(condition: boolean) {
   
  }
  constructor(
    private fb: FormBuilder,
    private service: SharedService,
    private route: Router,
    private LoaderService: LoaderService
  ) {}
  item: any;
  activateModal: boolean = false;

  load() {}
  cpdPass: any;
  getData() {
    this.enableForm();
    this.getPosition();
    this.getHomeCellGroup();
    this.getBibleStudyGroup();
    this.getMinstryGroup();
    this.getChurchService();
    this.churchDetails();
    this.getMemberDetails();
    this.setDOB();
    this.getAcademicLevel();
    this.getTitle();
    this.getDependants();
    // this.ccpdReturn = localStorage.getItem('ccpd');
    // this.cpdPass = this.ccpdReturn.replace(/"/g, '');

    // const secretKey = 'your-secret-key';
    // const encryptedData = localStorage.getItem('ccpd');
  }

  editClick(item: any) {
    this.item = item;
    this.activateModal = true;
//     this.service.setMemberID(this.meMemberID);
// this.service.setchurchID(this.meChurchID);
  }

  closeModal() {
    this.activateModal = false;
    this.getDependants();
  }
  closeModal1() {
    this.activateModal = false;
     this.getDependants();
  }

  setDOB(dob?: Date) {
    var age = moment().diff(dob, 'years');
    this.memberForm.patchValue({ age });
  }

  onFileChanged(event: any) {
    this.imageFile = event.target.files[0];
    // console.log(this.imageFile)

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        // this.setState({image: e.target.result});
        this.setState(e.target?.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  setState(image: any) {
    this.thumbnail = image;
  }

  logout() {
    this.service.logOut();
    this.route.navigate(['/register']);
  }

  updateMemberInfo(member: any) {
    this.btnSpinner = true;
    member.churchGroups = member.churchGroups?.toString();
    const formData = new FormData();
    formData.append('file', this.imageFile, this.imageFile?.name);
    keys(member).forEach((k) => {
      formData.append(k, member[k]);
    });
    this.service.updateMember(formData).subscribe(
      (data) => {
        if (data) {
          Alert.success('Record successfully saved');
          this.memberForm.reset();
          this.getData();
          this.btnSpinner = false;
          // console.log('xxxxxx', data)
        }
      },
      (error: any) => Alert.error('An error was encountered.')
    );
  }

  base64ToBlob(b64: string) {
    const byteString = window.atob(b64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  addDependant() {
    this.activateModal = true;
    this.service.setMemberID(this.meMemberID);
this.service.setchurchID(this.meChurchID);


  }
  editMemberInfo() {
    this.enableForm();
  }

  selectedPosition() {
    // console.log('------- her', this.selectedLevel.Positions)
  }

  async getDependants() {
    var usdata = localStorage.getItem('userLogin');
    this.userdata = JSON.parse(<any>usdata);
    let memberid = this.userdata.memberID;
    this.memberID = memberid;
    this.service.getDependantList(memberid).subscribe((data) => {
      this.DependantList = data;
      console.log("dependant information  >> ", data);
    });
  }

  async getPosition() {
    var usdata = localStorage.getItem('userLogin');
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    let memberid = this.userdata.memberID;
    this.churchID = churchid;
    this.memberID = memberid;
    this.service.getPositions(churchid).subscribe((data) => {
      this.churchPositions = data;
    });
  }

  churchDetails() {
    this.service.getChurchByID(this.churchID).subscribe((churchData) => {
      // console.log("my church details<<<<<", churchData[0]);
      this.churchName = churchData[0].Name;
      this.churchId = churchData[0].branchName;
    });
  }

  getMemberDetails() {
    this.loadSpinner = true;
    this.service.getMemberDetails(+this.memberID).subscribe((memberData) => {
          memberData.churchGroups = memberData.churchGroups?.split(',');
this.meMemberID=memberData.memberID;
this.meChurchID=memberData.churchID;
      


      console.log('This is my infromation >>>>>>>>>>>', memberData);

      if (memberData.photo != '') {
        this.thumbnail = `data:image/png;base64,${memberData.photo}`;
        let blob = this.base64ToBlob(memberData.photo);
        this.imageFile = new File([blob], 'profilePhoto', {
          type: 'image/png',
        });
      } else {
        this.thumbnail = 'assets/avatar.png';
      }

      if (memberData.memberApproved == 'true') {
        this.checkApprovedMember = true;
        // console.log(`'member ', ${memberData.firstName + memberData.lastName} is ${memberData.memberApproved} `);
      } else {
        this.checkApprovedMember = false;
        // console.log(`'member ', ${memberData.firstName + memberData.lastName} ${memberData.memberApproved}`);
      }

      this.memberForm.patchValue(memberData);
      this.loadSpinner = false;

      // this.checkApprovedMember = true;
      // console.log('-----', memberData)
      this.setDOB(new Date(memberData.dob));

      this.userGlobal = memberData.memberID;
      console.log(this.userGlobal, 'cheking user ');
      
    });
  }

  async getHomeCellGroup() {
    var usdata = localStorage.getItem('userLogin');
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.HomeCellGroup(churchid).subscribe((data) => {
      this.homecellGroup = data;
    });
  }

  async getBibleStudyGroup() {
    var usdata = localStorage.getItem('userLogin');
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.BibleStudyGroup(churchid).subscribe((data) => {
      this.biblestudyGroup = data;
    });
  }

  async getAcademicLevel() {
    var usdata = localStorage.getItem('userLogin');
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.getAcademicLevel(churchid).subscribe((data) => {
      this.AcademicLevel = data;
    });
  }
  async getTitle() {
    var usdata = localStorage.getItem('userLogin');
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.getTitles(churchid).subscribe((data) => {
      this.Titles = data;
    });
  }

  async getMinstryGroup() {
    var usdata = localStorage.getItem('userLogin');
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.MinistryGroup(churchid).subscribe((data) => {
      this.ministryGroup = data;
    });
  }

  async getChurchService() {
    var usdata = localStorage.getItem('userLogin');
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.churchService(churchid).subscribe((data) => {
      this.churchService = data;
    });
  }

 
  enableForm() {
    this.memberForm = this.fb.group({
      memberID: new FormControl({ value: null, disabled: false }),
      churchID: new FormControl({ value: null, disabled: false }),
      title: new FormControl({ value: null, disabled: false }),
      firstName: new FormControl({ value: null, disabled: false }),
      middleName: new FormControl({ value: null, disabled: false }),
      lastName: new FormControl({ value: null, disabled: false }),
      gender: new FormControl({ value: null, disabled: false }),
      dob: new FormControl({ value: null, disabled: false }),
      ministryGroup: new FormControl({ value: null, disabled: false }),
      age: new FormControl({ value: null, disabled: false }),
      maritalStatus: new FormControl({ value: null, disabled: false }),
      residentialAddress: new FormControl({ value: null, disabled: false }),
      landmark: new FormControl({ value: null, disabled: false }),
      digitalAddress: new FormControl({ value: null, disabled: false }),
      postalAddress: new FormControl({ value: null, disabled: false }),
      email: new FormControl({ value: null, disabled: false }),
      phoneNumber: new FormControl({ value: null, disabled: false }),
      memberStatus: new FormControl({ value: null, disabled: false }),
      employment: new FormControl({ value: null, disabled: false }),
      occupation: new FormControl({ value: null, disabled: false }),
      educationLevel: new FormControl({ value: null, disabled: false }),
      service: new FormControl({ value: null, disabled: false }),
      churchGroups: new FormControl({ value: null, disabled: false }),
      positionInChurch: new FormControl({ value: null, disabled: false }),
      bibleStudyGroup: new FormControl({ value: null, disabled: false }),
      homeCellGroup: new FormControl({ value: null, disabled: false }),
      // Photo : new FormControl({ value: null, disabled: false }),
      status: new FormControl({ value: null, disabled: false }),
      inactive_Reason: new FormControl({ value: null, disabled: false }),
      baptized: new FormControl({ value: null, disabled: false }),
      baptizmaDate: new FormControl({ value: null, disabled: false }),
      marriageDate: new FormControl({ value: null, disabled: false }),
      entryDate: new FormControl({ value: null, disabled: false }),
      entryID: new FormControl({ value: null, disabled: false }),
      modifiedDate: new FormControl({ value: null, disabled: false }),
      modifiedBy: new FormControl({ value: null, disabled: false }),
      fPTemplate: new FormControl({ value: null, disabled: false }),
      remarks: new FormControl({ value: null, disabled: false }),
      communicant: new FormControl({ value: null, disabled: false }),
      holySpiritBaptism: new FormControl({ value: null, disabled: false }),
      transfered: new FormControl({ value: null, disabled: false }),
      transferedDate: new FormControl({ value: null, disabled: false }),
      transferedToFrom: new FormControl({ value: null, disabled: false }),
      deceased: new FormControl({ value: null, disabled: false }),
      dateofdeath: new FormControl({ value: null, disabled: false }),
      officer: new FormControl({ value: null, disabled: false }),
      // dependantID: new FormControl({ value: null, disabled: false }),
      relation: new FormControl({ value: null, disabled: false }),
      MemberApproved: new FormControl({ value: null, disable: false }),
      
    });
  }

  validatePasswords(): boolean {
    if (this.newPassword !== this.confirmPassword) {
      // Show error message
      this.errorMessage = 'New password and confirm password do not match';
      return false;
    }
    return true;
  }

  //   confirmCurrentPassword() {
  //     if (this.ccpdReturn == this.currentPassword) {
  //       console.log('yes');
  //   }
  //   else{
  //     console.log('nooooo');
  //   }
  //   console.log(this.ccpdReturn,'ccp')
  //   console.log(this.currentPassword,'current')
  // }

  passwordMsg: boolean = false;

  // checkNewPassword(): boolean {
  //   //compare newPassword with confirmPassword
  //   if (this.newPassword == this.confirmPassword) {
  //     return true;
  //   } else {
  //     // this.passwordMsg = true;
  //     // this.passwordmsg = 'Password Match';
  //     return false;
  //   }
  // }

  

  
  currentPSD: any;

  // confirmCurrentPassword(): boolean {
  //   if (this.cpdPass == this.currentPassword) {
  //     // console.log('yes');
  //     this.currentPSD = true;
  //     return true;
  //   } else {
  //     this.currentPSD = false;
  //     return false;
  //     // console.log('nooooo');
  //   }
  //   console.log(this.currentPassword, 'current');
  // }

  confirmCurrentPassword(): boolean {
    const secretKey = 'your-secret-key';
    const encryptedData = localStorage.getItem('ccpd');
    console.log('ecrypted', encryptedData);
    
    if (encryptedData) {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

      this.cpdPass = decryptedData.replace(/"/g, '');
      console.log('decrypted', this.cpdPass);
     
      if (this.cpdPass === this.currentPassword) {
      
        this.currentPSD = true;
        return true;
      }
    }
    
    this.currentPSD = false;
    return false;
  }

 
  changePasswordSubscription!: Subscription;


  // function decryptData(encryptedData: string, secretKey: string): string {
  //   const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  //   const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
  //   return decryptedData;
  // }

  async changePassword() {
    if (this.newPassword.trim() === '' || this.confirmPassword.trim() === '') {
      // Check if newPassword or confirmPassword is blank
      this.passwordChanged = 'Please enter a new password and confirm it.';
      return;
    }
  
    if (this.newPassword !== this.confirmPassword) {
      // Check if newPassword and confirmPassword match
      this.passwordChanged = 'New password and confirm password must match.';
      return;
    }
  
    const isCurrentPasswordConfirmed = this.confirmCurrentPassword();
  
    if (!isCurrentPasswordConfirmed) {
      this.passwordChanged = 'Please enter the correct current password.';
      return;
    }
  
    let myID = this.userGlobal;
    let myNewPass = this.confirmPassword;
  
    this.changePasswordSubscription = this.service.changePassword(myID, myNewPass).subscribe(
      (response) => {
        if (response.status == 200) {
          console.log('Password changed processing');
        }
      },
      (error) => {
        if (error.status == 200) {
          this.service.removvePD();
          console.log('Password changed successfully');
          this.passwordChanged = 'Password changed successfully';
          Alert.success('Password changed successfully. Login with the new password');
  
          // Reload the page
          this.route.navigate(['/register']).then(() => {
            window.location.reload(); // Reload the page
          });
        } else {
          this.passwordChanged = 'password changing not successfull';
          console.log('password changing not successull', error.status);
        }
      }
    );
  
    // Check if the request is still loading
    if (this.changePasswordSubscription && !this.changePasswordSubscription.closed) {
      this.processingPassword = true;
      
    }
  }
  

  ngOnInit(): void {
    this.getData();
    this.load();
    this.LoaderService.showLoader();
    this.confirmCurrentPassword();
    // this.confirmCurrentPassword()
  }
}
