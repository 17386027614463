import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SharedService } from "src/app/shared.service";
import { Alert } from "src/app/shared/message-helper";
import { Location } from "@angular/common";
@Component({
  selector: "app-not-confirmed",
  templateUrl: "./not-confirmed.component.html",
  styleUrls: ["./not-confirmed.component.css","../dashbboard-style.css"],
})
export class NotConfirmedComponent implements OnInit {

  status = false;
  loading: boolean | undefined;
  addToggle() {
    this.status = !this.status;
  }

  allUnconfirmMembers: any;
  confirmed: any = false;
  constructor(private service: SharedService, private route:Router, private location: Location) {}
 
  // getUnconfirmedMembers() {
  //   this.service.GetUnConfirmedMembers().subscribe((r) => {
  //     this.allUnconfirmMembers = r;
  //   });
  // }

  getUnconfirmedMembers() {
    this.loading = true; // Set loading to true before making the request
    this.service.GetUnConfirmedMembers().subscribe(
      (r) => {
        this.allUnconfirmMembers = r;
        // console.log('<<data for unconfirmed ---- -----', this.allUnconfirmMembers);
        this.loading = false; // Set loading to false after successfully receiving the data
      },
      (error) => {
        console.log('Error occurred:', error);
        this.loading = false; // Set loading to false in case of an error
      }
    );

    // this.service.getMemberByStatus('Unconfirmed').subscribe(
    //   (r) => {
    //     this.allUnconfirmMembers = r;
    //     // console.log('<<data for unconfirmed ---- -----', this.allUnconfirmMembers);
    //     this.loading = false; // Set loading to false after successfully receiving the data
    //   },
    //   (error) => {
    //     console.log('Error occurred:', error);
    //     this.loading = false; // Set loading to false in case of an error
    //   }
    // );
  }

  
  ApproveMember(memberId: any) {
        this.service.approveMember(memberId).subscribe((r) => {
        // console.log("approve", r);
        Alert.success("Member Successfully Approved");
        // alert("")
        this.getUnconfirmedMembers();
      },
      (error) => {

        console.log("----->", error);
        // this.route.navigate(['/members-list']);
      }  
     
    );
    // this.confirmed  = true,
  }


  // ActivateMember(memberId: any) {
  //   this.service.approveMember(memberId).subscribe((r) => {
  //       // console.log("approve", r);
  //       Alert.success("Member Activated Successfully");
       
  //   this.getMemberByStatus();
  //     },
  //     (error) => {

  //       console.log("----->", error);
  //       // this.route.navigate(['/members-list']);
  //     }  
     
  //   );
  //   // this.confirmed  = true,
  // }






  deleteMember(memberId:any){
    this.service.deleteMember(memberId).subscribe(r => {
      Alert.error("Record deleted successfully. ");
      this.getUnconfirmedMembers();
      
      // this.deleted = true
      // if(r.length == 0){
        // Alert.success("Record deleted successfully ");
        // this.getUnconfirmedMembers();
        // console.log('deleted')
      // }
      // this.route.navigate(['/members-list']);
      // this.deleted = false;
    });
  
    // console.log('okay')
  }

  ngOnInit(): void {
    // Alert.success('Hi there');
    this.getUnconfirmedMembers();
  }
}
