export class WebUtils{
    public static getIsoDateString(date:any){
        try {
            if(!date) return null;
            date=new Date();
            return date= date.toISOString().substring(0,10)
        } catch (error) {
            return null;
        }
    }
}