import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { LoaderService } from "src/app/loader-service.service";
import { SharedService } from "src/app/shared.service";
import { Alert } from "src/app/shared/message-helper";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  registerationForm: FormGroup = new FormGroup({});

  title = "DKAWCAPP";
  toast: boolean = true;
  loginError: any;

  mechurchID: any;
  signUpForm!: FormGroup;
  signInForm!: FormGroup;
  forgottenPasswordForm!: FormGroup;

  sign!: FormGroup;
  memberId: any;
  regType: any;
  signInState = false;
  submitted = false;
  signedIN = false;
  invalidSign = false;
  disableSubmit = true;
  SelectedChurchBranch = false;
  confirmPAss = false;
  churchBranches: any = [];
  singinMember: any;
  regix:any;
  // getDate:any;

  loading:any;

  getDate = new Date().getFullYear();
  constructor(
    private fb: FormBuilder,
    private service: SharedService,
    private route: Router,
    private spinnerLoader: LoaderService
  ) {
    this.signedIN = true;
  }

  get f() {
    return this.signUpForm.controls;
  }
  get s() {
    return this.signInForm.controls;
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.MustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  ngOnInit(): void {
    this.setup();
    this.setupSignIn();
    this.getBranches();
    this.setupforgottenPassword();
  }

  forgottenPassword(email: any) {}

  AdminSignIn(loginInfo: any) {
    this.loading = true;
    this.signedIN = true;
    if (this.signInForm.invalid) {
      return;
    } else {
      let username = loginInfo.username;
      let password = loginInfo.password;

      let auth = "username=" + username + "&password=" + password;
      
      this.spinnerLoader.showLoader();

      this.service.adminSignIn(auth).subscribe(
        (user) => {
        this.service.adminlogInData(user);
        this.singinMember = user;
        
        let memberid = this.singinMember.memberID;
        console.log('log here', memberid);

        if (memberid) {
          this.loading = false;
          Alert.success("Successfully Logged In, Page loading");
          this.signInState = true;
          
          this.clear();
          this.route.navigate(["/MainAdminDashboard"]);
          console.log('memberID', memberid)
        } else {
          console.log("Unable to Login. Please check your entries");

          this.invalidSign = true;
          // Alert.error("Unable to Login. Please check your entries")
        }
      },(error)=>{ 
        this.loading = false;
        // alert(error.error)
        this.toast = false;
        this.loginError = error.error;
        console.log('error', (error));
      });
    }
  }

  recoverPass: boolean = false;
  recoveryEmail: string = "";

  async recoverPassword() {
    if (this.recoveryEmail == "") Alert.error("enter recovery email");
    var res = await this.service.saveMember(this.recoveryEmail).toPromise();
    if (res) {
      this.recoverPass = false;
      Alert.success("Recovery password sent to your email");
    } else {
      console.error(res);
    }
  }

  backToSignIn() {
    this.recoverPass = false;
  }
  hideRecovery() {
    this.regix = true;
    this.recoverPass = false;
    this.submitted = true;
  }
  change(e: any) {
    console.log(e);
  }

  async signUp(signUpInfo: any) {
    this.submitted = true;
    if (this.signUpForm.invalid) {
      return;
    } else {
      if (this.regType == "New") {
        await this.getLastId();
        console.log(this.memberId);
        signUpInfo.memberID = this.memberId;
        signUpInfo.churchID = parseInt(signUpInfo.churchID);
        var signup = await this.service.signup(signUpInfo).toPromise();
        signUpInfo.churchID = parseInt(signUpInfo.churchID);
        signUpInfo.deceased = "No";
        signUpInfo.Status = "Active";
        signUpInfo.entryDate = new Date();
        signUpInfo.entryID = this.memberId.toString();
        signUpInfo.modifiedDate = new Date();
        signUpInfo.modifiedBy = this.memberId.toString();
        var res = await this.service.saveMember(signUpInfo).toPromise();
        console.log("New member SignUp ", res);
        if (res.firstName != "") {
          Alert.success("Successfully Registered"); 
          this.clear();
          this.route.navigate(["/admin-login"]);
        } else {
          Alert.error("Unable to register. Please check your entries");
        } 
      } else {
        signUpInfo.memberID = parseInt(signUpInfo.memberID);
        signUpInfo.churchID = parseInt(signUpInfo.churchID);
        var signup = await this.service.signup(signUpInfo).toPromise();
        if (res.firstName != "") {
          Alert.success("Successfully Created Login Account");
          // this.route.navigate(['/home']);
          // this.signIn
        } else {
          Alert.error("Unable to register. Please check your entries");
        }
      }
    }
  }

  clear() {
    this.signUpForm.reset();
    this.signInForm.reset();
  }

  selectedbranch(churchID: any) {
    this.mechurchID = parseInt(churchID);
    if (this.mechurchID) { 
    }
  }

  selectedRegType(type: any) {
    console.log(type);
    this.SelectedChurchBranch = true;
  }

  getBranches() {
    this.service.getBranches().subscribe((data) => {
      this.churchBranches = data;

      if (data?.length > 0) this.disableSubmit = false;
    });
  }

  async getLastId() {
    var memberID = await this.service
      .getLastMember(this.mechurchID)
      .toPromise();
    this.memberId = memberID + 1;
  }

  searchMember(member: any) {
    var obj = {
      dOB: member.dOB,
      memberID: member.memberID,
      firstName: member.firstName,
    };
    this.service.searchMember(obj).subscribe((data) => {
      this.signUpForm.patchValue(data);
      console.log(data);
      this.selectedbranch(data.churchID);
    });
  }

  setup() {
    this.signUpForm = this.fb.group(
      {
        firstName: new FormControl(null, [Validators.required]),
        middleName: null,
        lastName: new FormControl(null, [Validators.required]),
        phoneNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(10),
        ]),
        password: new FormControl("", [
          Validators.required,
          Validators.minLength(6),
        ]),
        confirmPassword: new FormControl("", [Validators.required]),
        memberID: null,
        email: new FormControl(null, [Validators.required, Validators.email]),
        dOB: new FormControl(null, [Validators.required]),
        churchID: new FormControl("", [Validators.required]),
        username: new FormControl(null, [Validators.required]),
      },
      {
        validators: this.mustMatch("password", "confirmPassword"),
      }
    );
  }

  setupSignIn() {
    this.signInForm = this.fb.group({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl("", [
         Validators.required,
        // Validators.minLength(6),
      ]), 
    });
  }
  setupforgottenPassword() {
    this.forgottenPasswordForm = this.fb.group({
      email: null,
    });
  }

  get controls() {
    return this.signUpForm.controls;
  }

  logOut(){
    this.service.adminlogOut();
  }
}
