<app-top-nav></app-top-nav>
<div class="container-fluid">
  <div class="row">
    <nav id="sidebarMenu" class="col-md-4 col-lg-3 d-md-block bg-light sidebar collapse">

      <app-side-nav></app-side-nav>
    </nav>
    <main class="col-md-8 ms-sm-auto col-lg-9 px-md-4">
      <div class="collapse" id="collapseExample">

      </div>
      <div *ngIf="deleted">
        <div class="alert alert-primary" role="alert">
          A simple primary alert—check it out!
        </div>
      </div>
      <div class="table-responsive">
        <div class="container px-4 py-5" id="custom-cards">
          <div class="row">
            <div class="col-md-8">
              <h2 class="pb-2 border-bottom">Membership List</h2>
            </div>
            <div class="col-md-4"><button class="button btn btn-dark" (click)="print()"
                style="float: right;">print</button></div>

          </div>
          <div class="table-responsive">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Age</th>
                  <th scope="col">BSG</th>
                  <th scope="col">Baptized</th>
                  <th scope="col">Position</th>
                  <th scope="col">Add</th>
                  <!-- <th scope="col">Action</th> -->

                </tr>
              </thead>
              <tbody *ngFor="let user of allMembers">
                <tr>
                  <td>{{user.memberID}}</td>
                  <td>{{user.firstName}} {{user.middleName}} {{user.lastName}}</td>
                  <td>{{user.phoneNumber}}</td>
                  <td>{{user.email}}</td>
                  <td>{{user.gender}}</td>
                  <td>{{user.age}}</td>
                  <td>{{user.bibleStudyGroup}}</td>
                  <td>{{user.baptized}}</td>
                  <td>{{user.positionInChurch}}</td>
                  <td>{{user.residentialAddress}}</td>
                  <!-- <td>
                    <i class="far fa-edit"></i>
                    <i class="fas fa-trash text-danger" (click)="deleteMember(user.memberID)"></i>

                  </td> -->
                </tr>
              </tbody>

              <div *ngIf="!allMembers">
                <img src="../../../assets/Spinner-1s-247px.svg" width="90px" alt="" srcset="">
              </div>
            </table>
          </div>
        </div>

      </div>

    </main>
  </div>
</div>