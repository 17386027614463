import { Alert } from 'src/app/shared/message-helper';
import { SharedService } from './../../shared.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-members-active',
  templateUrl: './members-active.component.html',
  styleUrls: ['../dashbboard-style.css'],
})
export class MembersActiveComponent implements OnInit {
  allActiveMembers: any;
  allMembers: any;
  activeMembers: any;
  isLoading: boolean | undefined;

  constructor(private service: SharedService) {}
  status = false;
  addToggle() {
    this.status = !this.status;
  }

  // getMembersList() {
  //   this.service.GetAllMembers().subscribe((r) => {
  //     this.allActiveMembers = r;
  //     // console.log('---', this.allActiveMembers);
  //   });
  // }

  // getMemberByStatus() {
  //   this.service.GetMemberByStatus('Active').subscribe(
  //     (data) => {
  //       this.activeMembers = data;
  //       // console.log('acvite members', this.activeMembers);
  //     },
  //     (error) => {
  //       console.error('Error>>:', error);
  //     }
  //   );
  // };

  getMemberByStatus() { 
    this.isLoading = true; // Set loading state to true 
    this.service.GetMemberByStatus('Active').subscribe( 
      (data) => { 
        this.activeMembers = data; 
        console.log('Acvtive member', this.activeMembers);
        this.isLoading = false;
      }, 
      (error) => { 
        console.error('Error>>:', error); 
        this.isLoading = false;
      } 
    ); 
  };


  deActivateMember(memberId: any) {
    this.service.DeActiviate(memberId).subscribe((r) => {
        // console.log("approve", r);
        Alert.success("Member De-Activated Successfully");
        // alert("")
        this.getMemberByStatus();
      },
      (error) => {

        // console.log("----->", error);
        // this.route.navigate(['/members-list']);
      }  
     
    );
    // this.confirmed  = true,
  }

  ngOnInit(): void {
    // this.getMembersList();
    this.getMemberByStatus();
  }
}
