import { Component, OnInit } from "@angular/core";
import { SharedService } from "src/app/shared.service";
import { Alert } from "src/app/shared/message-helper";

@Component({
  selector: "app-members-inactive",
  templateUrl: "./members-inactive.component.html",
  styleUrls: ["../dashbboard-style.css"],
})
export class MembersInactiveComponent implements OnInit {
  allInActiveMembers: any;
  inActiveMembers: any;
  members:any;
  loading: any;

  constructor(private service: SharedService) {}

  status = false;
  addToggle() {
    this.status = !this.status;
  }



  getMemberByStatus() {
    this.service.GetMemberByStatus('Inactive').subscribe(data => {
      this.inActiveMembers = data;
      if (this.inActiveMembers.length <=0) {
        this.members = "No Members";
      }
      // console.log('InAvite members', this.inActiveMembers)
    }, error => {
      console.error('Error:', error);
    });
  }


  
  ActivateMember(memberId: any) {
    this.service.approveMember(memberId).subscribe((r) => {
        // console.log("approve", r);
        Alert.success("Member Activated Successfully");
       
    this.getMemberByStatus();
      },
      (error) => {

        console.log("----->", error);
        // this.route.navigate(['/members-list']);
      }  
     
    );
    // this.confirmed  = true,
  }

  


  
  ngOnInit(): void {
    // this.getMembersList();
    this.getMemberByStatus();
  }
}
