import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  dbData: any;
  level:any;
  constructor(private service: SharedService, private route: Router) { }

  ngOnInit(): void {
    this.level = this.service.getAdminLoginData()?.access_level;
  }
  
  logOut(){
    this.service.adminlogOut();
    this.route.navigate(['/admin-login'])
  }

  // dashboardData(){
  //   this.service.getAdminLoginData().subscribe(() =>{
  //     this.dbData = r;
  //     console.log(console.log('sssss', this.dbData))
  //   });
  // }
}
 