import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  //  readonly APIUrl = 'http://localhost:56203/api/';
  // readonly APIUrl = 'http://localhost:5200/api/';
  // readonly APIUrl = "https://ixodgiz68i.execute-api.us-east-2.amazonaws.com/api/";
  readonly APIUrl = "https://webapi.thecopdkat.org:2024/api/"; 
  // readonly APIUrl = "https://ixodgiz68i.execute-api.us-east-2.amazonaws.com/api/";
  // readonly photoUrl = "http://localhost:53535/Photos";

  //  secretKey = 'your-secret-key';
 
  private memberID = new BehaviorSubject('');
  getMemberID=this.memberID.asObservable();
  private churchID = new BehaviorSubject('');
  getchurchID=this.churchID.asObservable();


  constructor(private http: HttpClient) {}

  setMemberID (memberId: any){
    this.memberID.next(memberId)
    }

 setchurchID (churchId: any){
    this.churchID.next(churchId)
    }

  getDashboardData(): Observable<any> {
    return this.http.get<any>(this.APIUrl + 'MemberRegistration/dashboarddata');
  }

  getBranches(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + 'Churches');
  }

  getPositions(churchID: any): Observable<any[]> {
    return this.http.get<any[]>(
      this.APIUrl + `PositionInChurch/GetPosition?ChurchID=` + churchID
    );
  }

  getChurchByID(churchID: any): Observable<any> {
    return this.http.get<any>(
      this.APIUrl + `Churches/GetChurchByID?churchID=` + churchID
    );
  }
  // getLastMember(churchID: any): Observable<any> {
  //   //  var rec={churchID:churchID}
  //   return this.http.get<any>(
  //     this.APIUrl + `MemberRegistration/LastMemberID?churchID=` + churchID
  //   );
  // }

 getLastMember(churchID: any): Observable<any> {
    //  var rec={churchID:churchID}
    return this.http.get<any>(
      this.APIUrl + `MemberLogin/LastMemberID?churchID=` + churchID
    );
  }
  

  getMemberDetails(memberID: any): Observable<any> {
    //  var rec={churchID:churchID}
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/FindMember?MemberID=` + memberID
    );
  }

  deleteMember(memberID: any): Observable<any> {
    return this.http.delete<any[]>(
      this.APIUrl + `MemberRegistration/DeleteMember?MemberID=` + memberID
    );
  }

  DeleteAdminMember(memberID: any): Observable<any> {
    return this.http.delete<any[]>(
      this.APIUrl + `AdminUser/DeleteAdminMember?MemberID=` + memberID
    );
  }

  // updatedAdminMember(memberID: any): Observable<any> {
  //   return this.http.post<any[]>(
  //     this.APIUrl + `AdminUser/UpdateAdminMember?MemberID=` + memberID
  //   );
  // }


  updateAdminMember(updatedUser: any): Observable<boolean> {
    return this.http.put<boolean>(this.APIUrl + `AdminUser/UpdateAdminMember`, updatedUser);
  }

  data: any;

  approveMember(memberID: any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}MemberRegistration/ApproveMember?MemberID=${memberID}`);
  }

  DeActiviate(memberID: any): Observable<any[]> {
    return this.http.put<any[]>(
      this.APIUrl + `MemberRegistration/DeactivateMember?MemberID=` + memberID,
      memberID
    );
  }
  // approveMember(memberID: any): Observable<any[]> {
  //   return this.http.put<any[]>(
  //     this.APIUrl + `MemberRegistration/ApproveMember?MemberID=` + memberID);
  // }

  getDependantList(memberID: any): Observable<any> {
    //  var rec={churchID:churchID}
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/DependantList?dependant=` + memberID
    );
  }

  createNewMemeberID(member: any): Observable<any> {
    return this.http.post<any>(this.APIUrl + 'MemberLogin', member);
      }

  signup(member: any): Observable<any> {
    return this.http.put<any>(this.APIUrl + 'MemberLogin', member);
      }  

  updateMember(member: any): Observable<any> {
    return this.http.put<any>(
      this.APIUrl + 'MemberRegistration/updatemember',
      member
    );
  }

  searchMember(member: any): Observable<any> {
    let params = new URLSearchParams(member);
    return this.http.get<any>(
      this.APIUrl + 'MemberRegistration/searchmember?' + params
    );
  }

  // signIn(username:any,password:any):Observable<any>{
  //   return this.http.get<any>(this.APIUrl+'MemberLogin/MemberSignIn?Username='+ username + '&Password=' + password);
  // }

  signIn(auth: any): Observable<any> {
    return this.http.get<any>(this.APIUrl + 'MemberLogin/membersignin?' + auth);
  }

  // Test

  // Admin Section

  // adminSignIn(auth: any): Observable<any> {
  //   return this.http.get<any>(this.APIUrl + "AdminUser/adminUserLogin?" + auth);
  // }

  adminSignIn(auth: any): Observable<any> {
    return this.http.get<any>(this.APIUrl + 'AdminUser/adminUserLogin?' + auth);
  }

  saveMember(save: any): Observable<any> {
    return this.http.post<any>(this.APIUrl + 'MemberRegistration', save);
  }

  HomeCellGroup(churchID: any): Observable<any> {
    return this.http.get<any>(
      this.APIUrl + `HomeCellGroup/GetHomCellGroup?churchID=` + churchID
    );
  }

  getAcademicLevel(churchID: any): Observable<any> {
    return this.http.get<any>(
      this.APIUrl + `AcademicLevel/GetAcademicLevel?ChurchID=` + churchID
    );
  }

  getTitles(churchID: any): Observable<any> {
    return this.http.get<any>(
      this.APIUrl + `Titles/GetTitles?churchID=` + churchID
    );
  }

  BibleStudyGroup(churchID: any): Observable<any> {
    return this.http.get<any>(
      this.APIUrl + `BibleStudyGroup/GetBibleStudyGroup?churchID=` + churchID
    );
  }

  MinistryGroup(churchID: any): Observable<any> {
    return this.http.get<any>(
      this.APIUrl + `Ministries/GetGroup?churchID=` + churchID
    );
  }

  RecoverPassword(value: string): Observable<any> {
    return this.http.get<any>(
      // `${this.APIUrl}/MemberLogin/recoverpassword?value=${value}`
      // this.APIUrl + `MemberLogin/recoverpassword?value=` + value + '&option=' + option
      this.APIUrl + `MemberLogin/RecoverPassword?value=` + value
    );
  }

  churchService(churchID: any): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `ChurchService/GetServiceByID?churchID=` + churchID
    );
  }

  // Set Local Storage

  logInData(userLoginData: any) {
    localStorage.setItem('userLogin', JSON.stringify(userLoginData));
  }

  // verifyPD(cpd: any) {
  //   localStorage.setItem("ccpd", JSON.stringify(cpd));
  // }

  verifyPD(cpd: any) {
    const secretKey = 'your-secret-key';
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(cpd),
      secretKey
    ).toString();
    localStorage.setItem('ccpd', encryptedData);
  }

  // removvePD(){
  //   localStorage.removeItem("ccpd");
  // }

  removvePD() {
    const secretKey = 'your-secret-key';
    localStorage.removeItem('ccpd');
  }

  adminlogInData(adminLoginData: any) {
    localStorage.setItem('adminLogin', JSON.stringify(adminLoginData));
  }

  getAdminLoginData(){
   let adminMember =  localStorage.getItem('adminLogin');
   return adminMember ? JSON.parse(adminMember) : null;
  }

  adminlogOut() {
    localStorage.removeItem('adminLogin');
  }



  logOut() {
    localStorage.removeItem('userLogin');
  }

  // end local storage

  // http://localhost:5200/api/AdminUser/GetAdminMembersList

  GetAdminMembersList(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + `AdminUser/GetAdminMembersList`);
  }

  GetMembersList(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetMembersList`
    );
  }

  GetActiveMembers(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetActiveMembers`
    );
  }




  GetMemberByStatus(status: any): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetMemberByStatus?status=${status}`
    );
  }





  // GetAllMemberss(): Observable<any[]> {
  //   const activeUsers$ = this.GetMemberByStatus('active');
  //   const inactiveUsers$ = this.GetMemberByStatus('inactive');
  //   const unconfirmedUsers$ = this.GetMemberByStatus('unconfirmed');
  //   return forkJoin([activeUsers$, inactiveUsers$, unconfirmedUsers$]);
  // }

  getMembersListing(): Observable<any[]> {
    return this.http.get<any[]>
      (`${this.APIUrl}MemberRegistration/GetMembersList`);
  }
  

  GetInActiveMembers(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetInActiveMembers`
    );
  }

  GetDeceasedMembers(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetDeceasedMembers`
    );
  }

  GetTransferedMembersOut(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/TransferedMembersOut`
    );
  }

  GetTransferedMembersIn(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/TransferedMembersIn`
    );
  }

  GetAdultMembers(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetAdultMembers`
    );
  }

  GetChildMembers(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetChildMembers`
    );
  }

  GetUnConfirmedMembers(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetUnConfirmedMembers`
    );
  }

  GetConfirmedMembers(): Observable<any> {
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration/GetConfirmedMembers`
    );
  }

  GetAllMembers():Observable<any>{
    return this.http.get<any[]>(
      this.APIUrl + `MemberRegistration`
    );
  }

  // signIn(auth: any): Observable<any> {
  //   return this.http.get<any>(this.APIUrl + "MemberLogin/membersignin?" + auth);
  // }

  changePassword(memberId: any, newPassword: any): Observable<any> {
    return this.http.put<any[]>(
      this.APIUrl +
        `MemberLogin/ChangePassword?memberId=${memberId}&newPassword=${newPassword}`,
      {}
    );
    // return this.http.put<any[]>(`${this.APIUrl}ChangePassword?memberId=${memberId}`, { newPassword });
  }

  getMemberByStatus(status: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}MemberRegistration/GetMemberByStatus?status=${status}`);   
  }



  
  saveUser(user: any): Observable<any> {
    return this.http.post(`${this.APIUrl}AdminUser`, user);
  }

  // GetApprovedMembers(): Observable<any> {
  //   return this.http.get<any[]>(
  //     this.APIUrl + `MemberRegistration/MemberApproved`
  //   );
  // }  api/MemberLogin/ChangePassword'
}
 