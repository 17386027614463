<div class="container">
  <nav class="navbar">
    <div class="nav_icon" (click)="addToggle()">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
    <div class="navbar__left">
      <!-- <a href="#">Subscribers</a>
      <a href="#">Video Management</a> -->
      <a class="active_link" href="#">Admin</a>
    </div>
    <div class="navbar__right">
      <a href="#">
        <i class="fa fa-search" aria-hidden="true"></i>
      </a>
      <a href="#">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </a>
      <a href="#">
         <img width="30" src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g">
        
      </a>
    </div>
  </nav>
  <main>
    <div class="main__container">
      <!-- MAIN TITLE STARTS HERE -->
      <div class="main__title">
        <div class="main__greeting">
          <!-- <h1>Hello Admin</h1> -->
          <h4>Welcome to your admin dashboard</h4>
        </div>
      </div>
      <!-- MAIN TITLE ENDS HERE -->
      <!-- MAIN CARDS STARTS HERE -->
      <div class="main__cards">
        <div class="card">
          <i
            class="fa fa-user-o fa-2x text-lightblue"
            aria-hidden="true"
          ></i>
          <div class="card_inner">
            <p class="text-primary-p">Registered Members </p>
            <span class="font-bold text-title">   {{allDashboardData?.allMembers}}</span>
          </div>
        </div>
        <div class="card">
          <i class="fa fa-calendar fa-2x text-red" aria-hidden="true"></i>
          <div class="card_inner">
            <p class="text-primary-p">Active Members</p>
            <span class="font-bold text-title">  {{allDashboardData?.activeMembers}}</span>
          </div>
        </div>
        <div class="card">
          <i
            class="fa fa-video-camera fa-2x text-yellow"
            aria-hidden="true"
          ></i>
          <div class="card_inner">
            <p class="text-primary-p">In-Active Members</p>
            <span class="font-bold text-title">{{allDashboardData?.inActiveMembers}}</span>
          </div>
        </div>
        <div class="card">
          <i
            class="fa fa-thumbs-up fa-2x text-green"
            aria-hidden="true"
          ></i>
          <div class="card_inner">
            <p class="text-primary-p">Transfered In Members</p>
            <span class="font-bold text-title"> {{allDashboardData?.transferedMemberIn}}</span>
          </div>
        </div>
      </div>
     
      <!-- MAIN CARDS ENDS HERE -->
      
    </div>
  </main>
  <div id="sidebar" [ngClass]="status ? 'sidebar_responsive' : ''">
    <div class="sidebar__title">
      <div class="sidebar__img">
        <h1>Admin</h1>
      </div>
      <i
      (click)="addToggle()"
        class="fa fa-times"
        id="sidebarIcon"
        aria-hidden="true"
      ></i>
    </div>
   < <div class="sidebar__menu">
     <app-side-nav></app-side-nav>
    </div>
  </div>
</div>