
import { TopComponent } from './admin/top/top.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedService } from './shared.service';
import { SignInSignUpComponent } from './sign-in-sign-up/sign-in-sign-up.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MemberUpdateComponent } from './member-update/member-update.component';
import { AddDependantComponent } from './add-dependant/add-dependant.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { ErrorComponent } from './error/error.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { SideNavComponent } from './admin/side-nav/side-nav.component';
import { TopNavComponent } from './admin/top-nav/top-nav.component';
import { MembersListComponent } from './admin/members-list/members-list.component';
import { MembersActiveComponent } from './admin/members-active/members-active.component';
import { MembersInactiveComponent } from './admin/members-inactive/members-inactive.component';
import { MembersConfirmedComponent } from './admin/members-confirmed/members-confirmed.component';
// import { NonConfirmedComponent } from './admin/non-confirmed/non-confirmed.component';
import { NotConfirmedComponent } from './admin/not-confirmed/not-confirmed.component';
import { MembersDeceasedComponent } from './admin/members-deceased/members-deceased.component';
import { MembersTransferedComponent } from './admin/members-transfered/members-transfered.component';
import { TransferedInComponent } from './admin/transfered-in/transfered-in.component';
import { LoginComponent } from './admin/login/login.component';
import { MembersTransferedOutComponent } from './admin/members-transfered-out/members-transfered-out.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { MainAdminDashboardComponent } from './admin/main-admin-dashboard/main-admin-dashboard.component';

import { CommonModule } from '@angular/common';
import { NewComponent } from './admin/new/new.component';
import { AdultMembersComponent } from './admin/adult-members/adult-members.component';
import { ChildrenMembersComponent } from './admin/children-members/children-members.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInSignUpComponent,
    MemberUpdateComponent,
    AddDependantComponent,

    SpinnerLoaderComponent,
    ErrorComponent,
    DashboardComponent,
    SideNavComponent,
    TopNavComponent,
    MembersListComponent,
    MembersActiveComponent,
    MembersInactiveComponent,
    MembersConfirmedComponent,
    //  NonConfirmedComponent,
    NotConfirmedComponent,
    MembersDeceasedComponent,
    MembersTransferedComponent,
    TransferedInComponent,
    LoginComponent,
    MembersTransferedOutComponent,
    ProfileComponent,
    AdminUsersComponent,
    ReportsComponent,
    MainAdminDashboardComponent,
    SideNavComponent,
    NewComponent,
    TopComponent,
    AdultMembersComponent,
    ChildrenMembersComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AppRoutingModule,
    RouterModule,
    CommonModule,
  ],
  providers: [SharedService],
  bootstrap: [AppComponent],
})
export class AppModule {}
