import { SharedService } from "./../../shared.service";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  allDashboardData: any;
  constructor(private http: HttpClient, private service: SharedService) {}
  allMembers: any[] = [];
  allActive: any[] = [];
  allInActive: any[] = [];
  transferedIn: any[] = [];
  transferedOut: any[] = [];
  unConfirmedMember: any[] = [];

  getMembersList() {
    this.service.GetMembersList().subscribe((r) => {
      this.allMembers = r;
      // console.log('all data ---- -----',this.allMembers)
    });
  }

  getDashboardData() {
    this.service.getDashboardData().subscribe((r) => {
      this.allDashboardData = r;
      console.log('all dashboard data ---- -----',this.allDashboardData)
    });
  }

  getActiveMembersList() {
    this.service.GetActiveMembers().subscribe((r) => {
      this.allActive = r;
      // console.log('data ---- -----',this.allMembers)
    });
  }

  getInActiveMembersList() {
    this.service.GetInActiveMembers().subscribe((r) => {
      this.allInActive = r;
      // console.log("data ---- -----", this.allMembers);
    });
  }

  getTransferMembersIn() {
    this.service.GetTransferedMembersIn().subscribe((r) => {
      this.transferedIn = r;
      // console.log("in ---- -----", this.transferedIn);
    });
  }

  getTransferMembersOut() {
    this.service.GetTransferedMembersOut().subscribe((r) => {
      this.transferedOut = r;
      // console.log("out ---- -----", this.transferedOut);
    });
  }
  getUnConfirmedMembers() {
    this.service.GetUnConfirmedMembers().subscribe((r) => {
      this.unConfirmedMember = r;
      console.log("Unconfirmed registrant: ",this.unConfirmedMember)
    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.getMembersList();
    // this.getActiveMembersList();
    // this.getInActiveMembersList();
    // this.getTransferMembersIn();
    // this.getTransferMembersOut();
    // this.getUnConfirmedMembers();
    this.getDashboardData();
  }
}
