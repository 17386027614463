import { Component, OnInit } from '@angular/core';
import { SharedService } from './../../shared.service';

@Component({
  selector: 'app-members-transfered',
  templateUrl: './members-transfered.component.html',
  styleUrls: ['../dashbboard-style.css']
})
export class MembersTransferedComponent implements OnInit {
  status = false;
  members: any;
  addToggle() {
    this.status = !this.status;
  }
  
  show:any;
  allTransferdMembers: any;

  constructor(private service: SharedService) {}
 
  getTransferedMembers() { 
    this.show = true;
    this.service.GetTransferedMembersIn()
    .subscribe((r) => {
      this.allTransferdMembers = r;
      // if (this.allTransferdMembers > 0 && this.allTransferdMembers.length  ) {
        if (this.allTransferdMembers.length <=0) {
          this.members = "No Members";
        }
      // }
      this.show = false;
      // console.log(this.allTransferdMembers, '----------- tr')
    });
  }

  ngOnInit(): void {
    // this.getTransferedMembers();
  }

}
