<div class="container">
  <nav class="navbar">
    <div class="nav_icon" (click)="addToggle()">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
    <div class="navbar__left">
      <!-- <a href="#">Subscribers</a>
      <a href="#">Video Management</a> -->
      <a class="active_link" href="#">Admin</a>
    </div>
    <div class="navbar__right">
      <a href="#">
        <i class="fa fa-search" aria-hidden="true"></i>
      </a>
      <a href="#">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </a>
      <a href="#">
        <img
          width="30"
          src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g"
        />
      </a>
    </div>
  </nav>
  <main>
    <div class="main__container">
      <!-- MAIN TITLE STARTS HERE -->
      <div class="main__title">
        <div class="main__greeting">
          <!-- <h1>Hello Admin</h1> -->
          <!-- <h4>Welcome to your admin dashboard</h4> -->
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <!-- <div class="container px-4 py-5" id="custom-cards"> -->
            <h2 class="pb-2 border-bottoms">Membership List</h2>
            <div class="table-responsive">
              <table class="table table-stripeds table-sm">
                <!-- <thead> -->
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Gender</th>
                  <!-- <th scope="col">Position</th> -->
                  <!-- <th scope="col">Address</th> -->
                  <th scope="col">status</th>
                </tr>
                <!-- </thead> -->
                <tbody><br>
                  <tr *ngFor="let user of allMemebresRegistered">
                    <td>{{user.memberID}}</td>
                    <td>
                      {{user.firstName}} {{user.middleName}} {{user.lastName}}
                    </td>
                    <td>{{user.phoneNumber}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.gender}}</td>
                    <!-- <td>{{user.positionInChurch}}</td> -->
                    <!-- <td>{{user.residentialAddress}}</td> -->
                    <td>{{user.status}}</td>
                    <td>
                      
                      <button
                        type="button"
                        class="btn p-1 btn-danger"
                        data-bs-toggle="modal"
                        [attr.data-bs-target]="'#exampleModal' + user.memberID"
                      >
                        Remove
                      </button>
                    <!-- Modal -->
                    <div
                      class="modal"
                      [id]="'exampleModal' + user.memberID"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered" style="margin: 0px auto;
                        padding: 0px auto;">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h2 class="modal-title" id="exampleModalLabel">                           
                            </h2>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <h2 class="text-center">Are you sure to delete <span class="text-green"> 
                               {{user.firstName}}  {{user.lastName}}</span></h2>
                          </div>
                          <div class="modal-footer p-1">
                            <button 
                              type="button"
                              class="btn btn-secondary p-1 m-1"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="button" class="btn btn-danger p-1" (click)="deleteMember(user.memberID);">
                              Yes Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                 </td> 
                </tr>
                </tbody>
              </table>

              <!-- Modal ends here  -->
              <div class="text-center mt-5" *ngIf="loading">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main> 

  <div id="sidebar" [ngClass]="status ? 'sidebar_responsive' : ''">
    <div class="sidebar__title">
      <div class="sidebar__img">
        <h1>Admin</h1>
      </div>
      <i
        (click)="addToggle()"
        class="fa fa-times"
        id="sidebarIcon"
        aria-hidden="true"
      ></i>
    </div>
    <
    <div class="sidebar__menu">
      <app-side-nav></app-side-nav>
    </div>
  </div>
</div>








   <!-- <button  class="btn btn-success p-1" *ngIf="user.status == 'InActive'" (click)="ApproveMember(user.memberID)">
                        Activate
                      </button>
                      <button  class="btn btn-danger p-1" *ngIf="user.status == 'Active'" 
                      (click)="DeActivateMember(user.memberID)">
                        Deactivate
                      </button> -->