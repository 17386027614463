import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader-service.service';
import { NotificationService } from './notification.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
 
  title = 'toaster-not';
  showLoader$ = this.loaderService.loadingAction$;
  
  constructor(private notifyService : NotificationService, private loaderService: LoaderService,) { }
  
  
  
  ngOnInit(): void {
  }


   
  // showToasterSuccess(){
  //     this.notifyService.showSuccess("Data shown successfully !!", "tutsmake.com")
  // }
   
  // showToasterError(){
  //     this.notifyService.showError("Something is wrong", "tutsmake.com")
  // }
   
  // showToasterInfo(){
  //     this.notifyService.showInfo("This is info", "tutsmake.com")
  // }
   
  // showToasterWarning(){
  //     this.notifyService.showWarning("This is warning", "tutsmake.com")
  // }

}