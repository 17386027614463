
<app-top-nav></app-top-nav>
<div class="container-fluid">
  <div class="row">
      <nav id="sidebarMenu" class="col-md-4 col-lg-3 d-md-block bg-light sidebar collapse">
 
   <app-side-nav></app-side-nav>
   </nav>
    <main class="col-md-8 ms-sm-auto col-lg-9 px-md-4">  
        <div class="collapse" id="collapseExample">
          <div class="card card-body">
            Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
          </div>
        </div>
      <!-- <h2>All Members</h2> -->
      <div class="table-responsive">
        <div class="container px-4 py-5" id="custom-cards">
          <h2 class="pb-2 border-bottom">Profile</h2>
      
        
          <div class="form-content">
            <!-- <div class="input-group flex-nowrap">
                <span class="input-group-text" id="addon-wrapping">Member ID</span>
                <input type="text" class="form-control" [(ngModel)]="memberID" type="text" class="form-control" id="MemberID" [attr.disabled]="true ? '' : null" aria-label="Username" aria-describedby="addon-wrapping">
                <button class="btn btn-primary" type="submit" id="article-update-button" (click)="logout()">Logout</button>
            </div>

            <hr>
            <div *ngIf="loadSpinner">
                <app-spinner-loader></app-spinner-loader>
            </div> -->
            <form [formGroup]="memberForm" (ngSubmit)="updateMemberInfo(memberForm.value)" class="row g-3" *ngIf="getMemberDetails">

                <div class="col-md-6">

                    <label for="Title" class="form-label label">Title </label>
                    <select class="form-select" formControlName="title" required id="Title">
                        <option value="" disabled selected></option>
                        <option *ngFor="let item of Titles" [value]="item['name']">{{item.name}}</option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="firstname" class="form-label label">First Name</label>
                    <input formControlName="firstName" type="text" class="form-control" id="firstName">
                </div>
                <div class="col-md-6">
                    <label for="middlename" class="form-label label">Middle Name</label>
                    <input formControlName="middleName" type="text" class="form-control" id="MiddleName">
                </div>

                <div class="col-md-6">
                    <label for="lastname" class="form-label label">Last Name</label>
                    <input formControlName="lastName" type="text" class="form-control" id="lastname">
                </div>

                <div class="col-md-6">
                    <label for="phoneNumber" class="form-label label">Phone Number</label>
                    <input formControlName="phoneNumber" type="text" class="form-control" id="PhoneNumber">
                </div>
                <div class="col-md-6">
                    <label for="email" class="form-label label">Email</label>
                    <input formControlName="email" type="text" class="form-control" id="Email">
                </div>

                <div class="col-md-6">
                    <label class="form-label label"> Date of Birth</label>
                    <input formControlName="dob" type="date" class="form-control" id="dob" (change)="setDOB(memberForm.value.dob)">
                </div>

                <div class="col-md-6">
                    <label for="gender" class="form-label label">Gender</label>
                    <select class="form-select" formControlName="gender" required id="gender">
                        <option value="" disabled selected>Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="age" class="form-label label">Age</label>
                    <input formControlName="age" type="number" class="form-control" id="age">
                </div>


                <div class="col-md-6">
                    <label for="maritalStatus" class="form-label label">Marital Status</label>
                    <select class="form-select" formControlName="maritalStatus" required id="maritalStatus">
                        <option value="" disabled selected></option>
                        <option value="Married">Married</option>
                        <option value="Single">Single</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Divorced">Widowed</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="maritalStatus" class="form-label label">Employment</label>
                    <select class="form-select" formControlName="employment" required id="maritalStatus">
                        <option value="" disabled selected>Select Employment</option>
                        <option value="Yes">Employed</option>
                        <option value="Yes">Self-Employed</option>
                        <option value="Yes">Unemployed</option>
                        <option value="Yes">Student</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="occupation" class="form-label label">Occupation</label>
                    <input formControlName="occupation" type="text" class="form-control" id="occupation">
                </div>

                <div class="col-md-6">
                    <label for="residentialAddress" class="form-label label">Residential Address</label>
                    <input formControlName="residentialAddress" type="text" class="form-control" id="residentialAddress">
                </div>
                <div class="col-md-6">
                    <label for="landmark" class="form-label label">Land Mark</label>
                    <input formControlName="landmark" type="text" class="form-control" id="landmark">
                </div>

                <div class="col-md-6">
                    <label for="digitalAddress" class="form-label label">Digital Address</label>
                    <input formControlName="digitalAddress" type="text" class="form-control" id="digitalAddress">
                </div>
                <div class="col-md-6">
                    <label for="postalAddress" class="form-label label">Postal Address</label>
                    <input formControlName="postalAddress" type="text" class="form-control" id="postalAddress">
                </div>

                <div class="col-md-6">
                    <label for="educationLevel" class="form-label label">Highest Academic Level</label>
                    <select class="form-select" formControlName="educationLevel" required id="educationLevel">
                        <option value="" disabled selected></option>
                        <option *ngFor="let item of AcademicLevel" [value]="item['educationLevel']">
                            {{item.educationLevel}}</option>
                    </select>
                </div>


                <div class="col-md-6">
                    <label for="Officer" class="form-label label">Are You a Church Officer?</label>
                    <select class="form-select" formControlName="officer" required id="officer">
                        <option value="" disabled selected>Select Officer</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>


                <div class="col-md-6">
                    <label class="form-label label">Position in Church</label>
                    <select class="form-select" formControlName="positionInChurch">
                        <option value=""> </option>

                        <option *ngFor="let item of churchPositions" [value]="item['positions']">{{item.positions}}
                        </option>
                    </select>
                </div>


                <div class="col-md-6">
                    <label for="bibleStudyGroup" class="form-label label">Bible Study Group</label>
                    <select class="form-select" formControlName="bibleStudyGroup" required id="bibleStudyGroup">
                        <option value="" disabled selected></option>
                        <option *ngFor="let item of biblestudyGroup" [value]="item['name']">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="homeCellGroup" class="form-label label">Home Cell Group</label>
                    <select class="form-select" formControlName="homeCellGroup" required id="homeCellGroup">
                        <option value="" disabled selected></option>
                        <option *ngFor="let item of homecellGroup" [value]="item['name']">{{item.name}}</option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="deceased" class="form-label label"> Select Ministries and Groups You belong to: </label>
                    <ng-select [items]="ministryGroup" [multiple]="true" bindLabel="ministry" bindValue="ministry" placeholder="Select ministry group" [closeOnSelect]="false" [hideSelected]="true" formControlName="churchGroups">
                    </ng-select>
                </div>

                <div class="col-md-6">
                    <label for="service" class="form-label label">Church Service</label>
                    <select class="form-select" formControlName="service" required id="service">
                        <option value="" disabled selected></option>
                        <option *ngFor="let item of churchService" [value]="item['name']">{{item.name}}</option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="baptized" class="form-label label">Baptized</label>
                    <select class="form-select" formControlName="baptized" required id="baptized">
                        <option value="" disabled selected>Select </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="baptizDate" class="form-label label">Baptizma Date</label>
                    <input formControlName="baptizmaDate" type="date" class="form-control" id="baptizDate">
                </div>
                <div class="col-md-6">
                    <label for="marriageDate" class="form-label label">Marriage Date</label>
                    <input formControlName="marriageDate" type="date" class="form-control" id="marriageDate">
                </div>
                <div class="col-md-6">
                    <label for="communicant" class="form-label label">Communicant</label>
                    <select class="form-select" formControlName="communicant" required id="communicant">
                        <option value="" disabled selected>Select </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>


                <div class="col-md-6">
                    <label for="holySpiritBaptism" class="form-label label">Holy Spirit Baptism</label>
                    <select class="form-select" formControlName="holySpiritBaptism" required id="holySpiritBaptism">
                        <option value="" disabled selected>Select </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="status" class="form-label label">Status</label>
                    <select class="form-select" formControlName="status" required id="status" [attr.disabled]="true ? '' : null">
                        <option value="" disabled selected>Select </option>
                        <option value="Active">Active</option>
                        <option value="Inactive">In Active</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="inactive_Reason" class="form-label label">Inactive Reason</label>
                    <input formControlName="inactive_Reason" [disabled]="true" type="text" class="form-control" id="inactive_Reason" [attr.disabled]="true ? '' : null">
                </div>
                <div class="col-md-6">
                    <label for="transfered" class="form-label label">Transfered </label>
                    <select class="form-select" formControlName="transfered" required id="transfered" [attr.disabled]="true ? '' : null">
                        <option value="" disabled selected>Select </option>
                        <option value="TransferedTo">TransferedTo</option>
                        <option value="TransferedFrom">TransferedFrom</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="transferedDate" class="form-label label">Transfered Date</label>
                    <input formControlName="transferedDate" type="date" class="form-control" id="transferedDate" [attr.disabled]="true ? '' : null">
                </div>
                <div class="col-md-6">
                    <label for="transferedToFrom" class="form-label label">Transfered To/From</label>
                    <input formControlName="transferedToFrom" type="text" class="form-control" id="transferedToFrom" [attr.disabled]="true ? '' : null">
                </div>

                <div class="col-md-6">
                    <label for="deceased" class="form-label label">Deceased</label>
                    <select class="form-select" formControlName="deceased" required id="deceased" [attr.disabled]="true ? '' : null">
                        <option value="" disabled selected>Select </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>


                <div class="mb-3">
                    <input type="file" class="form-control" aria-label="file example" (change)="onFileChanged($event)" #fileInpu required>
                    <div class="invalid-feedback">Add Photo</div>
                </div>
                <div class="col-md-6">
                    <img [src]="thumbnail" class="img-thumbnail" alt="image" width="120" height="120">
                </div>


                <div class="input-group flex-nowrap  col-md-6">
                    <button class="btn  btn-sml btn-success save float-right" type="submit">Save
                        Changes</button>
                    <!-- <i *ngIf="btnSpinner" class="fa fa-spinner fa-spin" style="margin-left: 15px;"></i> -->
                    <!-- <i *ngIf="btnSpinner" class="fa fa-spinner" aria-hidden="true"></i> -->
                    <!-- <i class="fa-solid fa-spinner mr-2" style="margin-left: 15px;"></i> -->
                    <!-- <img src="../../assets/spin.gif"> -->
                </div>

            </form>

        </div>
          
        </div>
      </div>
    </main>
  </div>
</div>
