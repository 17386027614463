import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from '../loader-service.service';
import { SharedService } from '../shared.service';
import { Alert } from '../shared/message-helper';
import { HttpResponse } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-sign-in-sign-up',
  templateUrl: './sign-in-sign-up.component.html',
  styleUrls: ['./sign-in-sign-up.component.css'],
})
export class SignInSignUpComponent implements OnInit {
  regix = false;
  toast: boolean = true;
  loginError: any;
  getDate = new Date().getFullYear();

  alert() {
    console.log('Method not implemented.');
  }

  registerationForm: FormGroup = new FormGroup({});

  title = 'DKAWCAPP';

  mechurchID: any;
  signUpForm!: FormGroup;
  signInForm!: FormGroup;
  forgottenPasswordForm!: FormGroup;

  sign!: FormGroup;
  memberId: any;
  regType: any;
  signInState = false;
  submitted = false;
  signedIN = false;
  invalidSign = false;
  disableSubmit = true;
  SelectedChurchBranch = false;
  confirmPAss = false;
  churchBranches: any = [];
  singinMember: any;
  loading: any;
lastMemberID: any;
  constructor(
    private fb: FormBuilder,
    private service: SharedService,
    private route: Router,
    private spinnerLoader: LoaderService
  ) {
    this.signedIN = true;
  }

  get f() {
    return this.signUpForm.controls;
  }
  get s() {
    return this.signInForm.controls;
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.MustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  ngOnInit(): void {
    this.setup();
    this.setupSignIn();
    this.getBranches();
    this.setupforgottenPassword();
  }

  forgottenPassword(email: any) {}

  signIn(loginInfo: any) {
    this.loading = true;
    this.signedIN = true;
    if (this.signInForm.invalid) {
      return;
    } else {
      let username = loginInfo.Username;
      let password = loginInfo.Password;

      let auth = 'Username=' + username + '&Password=' + password;

      this.spinnerLoader.showLoader();

      this.service.signIn(auth).subscribe(
        (user) => {
          this.service.logInData(user);
          this.singinMember = user;
          if (this.singinMember.length <= 0 || !this.singinMember) {
            // console.log('not Ok');
          } else {
            // console.log(' Ok');
          }
          let memberid = this.singinMember.memberID;
          if (memberid) {
            this.loading = false;
            Alert.success('Successfully Logged In, Page loading');
            this.route.navigate(['/updateMember']);
            this.signInState = true;
            this.clear();

            this.service.verifyPD(password);
          } else {
            Alert.error('Unable to Login. Please check your entries')
            // console.log('Unable to Login. Please check your entries');

            this.invalidSign = true;
            // Alert.error("Unable to Login. Please check your entries")
          }
        },
        (error) => {
          this.loading = false;
          // alert(error.error)
          this.toast = false;
          this.loginError = error.error;
        }
      );
    }
  }

  recoverPass: boolean = false;
  recoveryEmail: string = '';
  value: any;
  option: any;
  async recoverPassword() {
    // var res = await this.service.RecoverPassword(this.recoveryEmail,this.recoverPass).toPromise();

    var res = await this.service.RecoverPassword(this.value).toPromise();

    if (res == true) {
      this.recoverPass = false;
      Alert.success('Recovery password sent to your email');
    } else {
      Alert.error('Please, Valid Phone Number or Email');
    }
  }

  backToSignIn() {
    this.recoverPass = false;
  }
  hideRecovery() {
    this.regix = true;
    this.recoverPass = false;
    this.submitted = true;
  }
  change(e: any) {
    console.log(e);
  }

  async signUp(signUpInfo: any) {
    
    this.loading = true;
    this.submitted = true;

    if (this.signUpForm.invalid) {
      return;
      
    } else {
      if (this.regType == 'New') {
        // await this.getLastId();
        // console.log(this.memberId);

        // let username = signUpInfo.username;
        let password = signUpInfo.password;
        let memberPhone = signUpInfo.phoneNumber;
        signUpInfo.memberID = this.memberId;
        signUpInfo.churchID = parseInt(signUpInfo.churchID);
        signUpInfo.deceased = 'No';
        signUpInfo.Status = 'Unconfirmed';
        signUpInfo.entryDate = new Date();
        signUpInfo.entryID = this.memberId.toString();
        signUpInfo.modifiedDate = new Date();
        signUpInfo.modifiedBy = this.memberId.toString();
        signUpInfo.password;
var age = moment().diff(signUpInfo.dOB, 'years');
signUpInfo.age=age;

this.signInForm.value.Password = signUpInfo.password;
this.signInForm.value.memberID = this.memberId;
this.signInForm.value.churchID = parseInt(this.mechurchID);
this.signInForm.value.Username=signUpInfo.username;
this.signInForm.value.email=signUpInfo.email;
this.signInForm.value.firstName= signUpInfo.firstName;
this.signInForm.value.middleName=signUpInfo.middleName;
this.signInForm.value.lastName=signUpInfo.lastName;
this.signInForm.value.phoneNumber=signUpInfo.phoneNumber;
this.signInForm.value.dOB=signUpInfo.dOB; 

// console.log("Updateing member info:  ", this.signInForm.value);

 await this.service.signup(this.signInForm.value).toPromise();
        var res = await this.service.saveMember(signUpInfo).toPromise();
        
        // this.service.logInData(user);
        this.signInState = true;
        // console.log('New member SignUp ', res);

        if (res.firstName != '') {   
          
          this.loading = false;
          Alert.success('Successfully Registered');
          window.location.reload();
          var res = await this.service.RecoverPassword(memberPhone).toPromise();
          this.service.verifyPD(password);
       

          this.clear();
          window.location.reload();
        } else {
          Alert.error('Unable to register. Please check your entries');
        }
      } 
      
       else {
        signUpInfo.memberID = parseInt(signUpInfo.memberID);
        signUpInfo.churchID = parseInt(signUpInfo.churchID);
        var signup = await this.service.signup(signUpInfo).toPromise();
        if (res.firstName != '') {
          Alert.success('Successfully Created Login Account');
          // this.route.navigate(['/home']);
          // this.signIn
        } else {
          Alert.error('Unable to register. Please check your entries');
        }
      }
    }
  }

  clear() {
    this.signUpForm.reset();
    this.signInForm.reset();
  }

  selectedbranch(churchID: any) {
    this.mechurchID = parseInt(churchID);
    if (this.mechurchID) {
     this.getLastId();
    }

    // console.log('my branch<<<<<<<<  >>>>>>>> ',  this.mechurchID);
  }

  selectedRegType(type: any) {
    // console.log(type);
    this.SelectedChurchBranch = true;
  }

  getBranches() {
    this.service.getBranches().subscribe((data) => {
      this.churchBranches = data;

      if (data?.length > 0) this.disableSubmit = false;
    });
  }

  async getLastId() {
    var memberID = await this.service.getLastMember(this.mechurchID).toPromise();
    this.memberId = memberID + 1;
   
        this.signInForm.value.memberID = this.memberId;
        this.signInForm.value.churchID = parseInt(this.mechurchID);
        this.signInForm.value.Username="member" + this.memberId + '@thecopdkat.org';
        this.signInForm.value.email="member" + this.memberId + '@thecopdkat.org';
        this.signInForm.value.firstName= '';
        this.signInForm.value.middleName='';
        this.signInForm.value.lastName='';
        this.signInForm.value.phoneNumber='';
        this.signInForm.value.dOB=''       

//  console.log("new member id", this.signInForm.value);

        await this.service.createNewMemeberID(this.signInForm.value).toPromise();
  }

  searchMember(member: any) {
    var obj = {
      dOB: member.dOB,
      memberID: member.memberID,
      firstName: member.firstName,
    };
    this.service.searchMember(obj).subscribe((data) => {
      this.signUpForm.patchValue(data);
      // console.log(data);
      this.selectedbranch(data.churchID);
    });
  }

  setup() {
    this.signUpForm = this.fb.group(
      {
        memberID: null,
        firstName: new FormControl(null, [Validators.required]),
        middleName: null,
        lastName: new FormControl(null, [Validators.required]),
        phoneNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(10),
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
        email: new FormControl(null),
        dOB: new FormControl(null, [Validators.required]),
        churchID: new FormControl('', [Validators.required]),
        username: new FormControl(null, [Validators.required]),
             },
      {
        validators: this.mustMatch('password', 'confirmPassword'),
      }
    );
    [Validators.required, Validators.email];
  }

  setupSignIn() {
    this.signInForm = this.fb.group({
      Username: new FormControl(null, [Validators.required]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }
  setupforgottenPassword() {
    this.forgottenPasswordForm = this.fb.group({
      email: null,
    });
  }

  get controls() {
    return this.signUpForm.controls;
  }
}
