<!-- 
<app-top-nav></app-top-nav>
<div class="container-fluid">
  <div class="row">
      <nav id="sidebarMenu" class="col-md-4 col-lg-3 d-md-block bg-light sidebar collapse">
 
   <app-side-nav></app-side-nav>
   </nav>
    <main class="col-md-8 ms-sm-auto col-lg-9 px-md-4">  
        <div class="collapse" id="collapseExample">
          
        </div>
        <div class="table-responsive">
          <div class="container px-4 py-5" id="custom-cards">
            <h2 class="pb-2 border-bottom">System Admins</h2><div class="table-responsive">
              <table class="table table-striped table-sm" >
                <thead>
                  <tr>
                    <th scope="col">Member ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">User ID</th>
                    <th scope="col">Email</th>
                    <th scope="col">Access Level</th>

                  </tr> 
                </thead>
                <tbody *ngFor="let user of allAdminUsers">
                  <tr>     
                    <td>{{user.memberID}}</td>
                    <td>{{user.firstName}} {{user.middleName}} {{user.lastName}}</td>
                  <td>{{user.user_Id}}</td>
                  <td></td>
                    <td>{{user.access_level}}</td>

                  </tr>
                  </tbody>
                  <div *ngIf="!allAdminUsers">
                    <img src="/src/assets/Spinner-1s-247px.svg" alt="" srcset="">
                  </div>
                </table>
        </div>
        </div>
        
        </div>
      
    </main>
  </div>
</div> -->

<div class="container">
  <nav class="navbar">
    <div class="nav_icon" (click)="addToggle()">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
    <div class="navbar__left">
      <!-- <a href="#">Subscribers</a>
      <a href="#">Video Management</a> -->
      <a class="active_link" href="#">Admin</a>
    </div>
    <div class="navbar__right">
      <a href="#">
        <i class="fa fa-search" aria-hidden="true"></i>
      </a>
      <a href="#">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </a>
      <a href="#">
        <img
          width="30"
          src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g"
        />
      </a>
    </div>
  </nav>
  <main>
    <div class="main__container">
      <!-- MAIN TITLE STARTS HERE -->
      <div class="main__title">
        <div class="main__greeting">
          <!-- <h1>Hello Admin</h1> -->
          <!-- <h4>Welcome to your admin dashboard</h4> -->
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <!-- <div class="container px-4 py-5" id="custom-cards"> -->
            <div class="row">
              <div class="col">
                <h2 class="pb-2 border-bottoms">Admin Members</h2>
              </div>
              <div class="col">
                <!-- <div class="btn btn-primary">Add New members</div> -->
                <button
                  type="button"
                  class="btn p-1 btn-success"
                  data-bs-toggle="modal"
                  [attr.data-bs-target]="'#exampleModal'"
                >
                  Add New Admin Member
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-stripeds table-sm">
                <!-- <thead> -->
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">UserName</th>
                  <th scope="col">Access Level</th>
                  <th scope="col">Action</th>
                </tr>
                <!-- </thead> -->
                <tbody>
                  <tr *ngFor="let user of allAdminUsers">
                    <!-- <td>{{user.title == null ? '': user.MemberID}}</td> -->
                    <td>{{ user.memberID }}</td>
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.username }}</td>
                    <td>{{ user.access_level }}</td>
                    <!-- <td> -->

                    <!-- </td> -->
                    <td>
                      <i
                        class="far fa-edit p-2"
                        style="cursor: pointer"
                        data-bs-toggle="modal"
                          [attr.data-bs-target]="'#editModal'  + user.memberID"
                      ></i>
                      <!-- <button class="btn btn-dark p-1" (click)="ActivateMember(user.memberID)">Activate</button> -->
                      <i
                        class="fas fa-trash text-danger p-2"
                        data-bs-toggle="modal"
                        [attr.data-bs-target]="'#deleteModal' + user.memberID"
                        style="cursor: pointer"
                      ></i>

                      <div
                        class="modal"
                        [id]="'deleteModal' + user.memberID"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-dialog-centered"
                          style="margin: 0px auto; padding: 0px auto"
                        >
                          <div class="modal-content">
                            <div class="modal-header">
                              <h2
                                class="modal-title"
                                id="exampleModalLabel"
                              ></h2>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <h2 class="text-center">
                                Are you sure to delete
                                <span class="text-green">
                                  {{ user.firstName }} {{ user.lastName }}</span
                                >
                              </h2>
                            </div>
                            <div class="modal-footer p-1">
                              <button
                                type="button"
                                class="btn btn-secondary p-1 m-1"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="btn btn-danger p-1"
                                (click)="deleteAdminMember(user.memberID)"
                              >
                                Yes Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Modal for edit member   starts here------------------------------------------>

                      <!-- Button trigger modal -->
                      <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#editModal">
  Launch editModal
</button> -->

                       <!--START --- Modal to UPDATE Admin Member   ------------->
                      <!-- <div
                        class="modal fade"
                       [id]="'editModal' + user.memberID"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1" 
                        aria-labelledby="editModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="margin: 0px auto; padding: 0px auto">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="editModalLabel">
                                {{user.firstName}}
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body m-2 p-1 text-green">
                              <h4>Update Member Details <br /><br /></h4>
    
                              <div class="mb-3 row">
                                <div class="mb-3 row">
                                  <label
                                    for="inputMemberID"
                                    class="col-sm-2 col-form-label"
                                    >Member ID</label
                                  >
                                  <div class="col-sm-10">
                                    <div class="input-group mb-3">
                                      <input
                                        type="text"
                                        class="form-control"
                                        [(ngModel)]="user.memberID"
                                        placeholder="Enter Member ID"
                                        aria-label="Member ID"
                                        aria-describedby="button-addon2"
                                      />
                                    </div>
                                  </div>
                                </div>
                             
                               
                                <div *ngIf="user">
                                  <div class="col-sm-12">
                                    <div class="mb-3 row">
                                      <label
                                        for="userID"
                                        class="col-sm-2 col-form-label"
                                        >User ID</label
                                      >
                                      <div class="col-sm-10">
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="userID"
                                          value=""
                                          disabled
                                          [(ngModel)]="user.user_Id"
                                        />
                                      </div>
                                    </div>
                                    <div class="mb-3 row">
                                      <label
                                        for="churchID"
                                        class="col-sm-2 col-form-label"
                                        >Church ID</label
                                      >
                                      <div class="col-sm-10">
                                        <input
                                          type="text"
                                          disabled=""
                                          class="form-control"
                                          id="churchID"
                                          [(ngModel)]="user.churchID"
                                        />
                                      </div>
                                    </div>
                                    <div class="mb-3 row">
                                      <label
                                        for="username"
                                        class="col-sm-2 col-form-label"
                                        >Username</label
                                      >
                                      <div class="col-sm-10">
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="username"
                                          [(ngModel)]="user.username"
                                        />
                                      </div>
                                    </div>
                                    <div class="mb-3 row">
                                      <label
                                        for="password"
                                        class="col-sm-2 col-form-label"
                                        >Password</label
                                      >
                                      <div class="col-sm-10">
                                        <input
                                          type="password"
                                          class="form-control"
                                          id="password"
                                          [(ngModel)]="user.password"
                                        />
                                      </div>
                                    </div>
                                    <div class="mb-3 row">
                                      <label
                                        for="firstName"
                                        class="col-sm-2 col-form-label"
                                        >First Name</label
                                      >
                                      <div class="col-sm-10">
                                        <input
                                          type="text"
                                          disabled
                                          class="form-control"
                                          id="firstName"
                                          [(ngModel)]="user.firstName"
                                        />
                                      </div>
                                    </div>
                                    <div class="mb-3 row">
                                      <label
                                        for="lastName"
                                        class="col-sm-2 col-form-label"
                                        >Last Name</label
                                      >
                                      <div class="col-sm-10">
                                        <input
                                          type="text"
                                          disabled=""
                                          class="form-control"
                                          id="lastName"
                                          [(ngModel)]="user.lastName"
                                        />
                                      </div>
                                    </div>
                                    <div class="mb-3 row">
                                      <label
                                        for="accessLevel"
                                        class="col-sm-2 col-form-label"
                                        >Access Level</label
                                      >
                                      <select
                                        name="access_level"
                                        [(ngModel)]="user.access_level"
                                      >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
    
                              <hr />
                              <h5>By Saving you agree to make a member an admin</h5>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Understood
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <div
                      class="modal fade"
                      [id]="'editModal' + user.memberID"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1" 
                      aria-labelledby="editModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="margin: 0px auto; padding: 0px auto">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="editModalLabel">
                              <!-- {{user.firstName}} -->
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body m-2 p-1 text-green">
                            <h4>Update Member Details <br /><br /></h4>
                    
                            <div class="mb-3 row">
                              <div class="mb-3 row">
                                <label
                                  for="inputMemberID"
                                  class="col-sm-2 col-form-label"
                                  >Member ID</label
                                >
                                <div class="col-sm-10">
                                  <div class="input-group mb-3">
                                    <input
                                      type="text"
                                      class="form-control"
                                      [(ngModel)]="user.memberID"
                                      placeholder="Enter Member ID"
                                      aria-label="Member ID"
                                      aria-describedby="button-addon2"
                                    />
                                  </div>
                                </div>
                              </div>
                              <!-- <div *ngIf="loading">
                                <p>Loading...</p>
                              </div> -->
                              
                              <div *ngIf="user">
                                <div class="col-sm-12">
                                  <div class="mb-3 row">
                                    <label
                                      for="userID"
                                      class="col-sm-2 col-form-label"
                                      >User ID</label
                                    >
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="userID"
                                        value=""
                                        disabled
                                        [(ngModel)]="user.user_Id"
                                      />
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <label
                                      for="churchID"
                                      class="col-sm-2 col-form-label"
                                      >Church ID</label
                                    >
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        disabled=""
                                        class="form-control"
                                        id="churchID"
                                        [(ngModel)]="user.churchID"
                                      />
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <label
                                      for="username"
                                      class="col-sm-2 col-form-label"
                                      >Username</label
                                    >
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="username"
                                        [(ngModel)]="user.username"
                                      />
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <label
                                      for="password"
                                      class="col-sm-2 col-form-label"
                                      >Password</label
                                    >
                                    <div class="col-sm-10">
                                      <input
                                        type="password"
                                        class="form-control"
                                        id="password"
                                        [(ngModel)]="user.password"
                                      />
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <label
                                      for="firstName"
                                      class="col-sm-2 col-form-label"
                                      >First Name</label
                                    >
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        disabled
                                        class="form-control"
                                        id="firstName"
                                        [(ngModel)]="user.firstName"
                                      />
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <label
                                      for="lastName"
                                      class="col-sm-2 col-form-label"
                                      >Last Name</label
                                    >
                                    <div class="col-sm-10">
                                      <input
                                        type="text"
                                        disabled=""
                                        class="form-control"
                                        id="lastName"
                                        [(ngModel)]="user.lastName"
                                      />
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <label
                                      for="accessLevel"
                                      class="col-sm-2 col-form-label"
                                      >Access Level</label
                                    >
                                    <select
                                      name="access_level"
                                      [(ngModel)]="user.access_level"
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                    
                            <hr />
                            <!-- <h5>By Saving you agree to make a member an admin</h5> -->
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <!-- Add Save button to trigger the updateMemberInfo method -->
                            <button type="button" class="btn btn-primary" (click)="updateAdminMember(user)">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                      
                       <!--END --- Modal to UPDATE Admin Member   ------------->
                    </td>
                  </tr>

                  
                  <!--START --- Modal to ADD Admin Member   ------------->
                  <div
                    class="modal"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    [id]="'exampleModal'"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                      style="margin: 0px auto; padding: 0px auto"
                    >
                      <div class="modal-content">
                        <div class="modal-header">
                          <h2 class="modal-title" id="exampleModalLabel"></h2>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body m-2 p-1">
                          <h4>Enter Admin Member Details <br /><br /></h4>

                          <div class="mb-3 row">
                            <div class="mb-3 row">
                              <label
                                for="inputMemberID"
                                class="col-sm-2 col-form-label"
                                >Member ID</label
                              >
                              <div class="col-sm-10">
                                <div class="input-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="searchQuery"
                                    (input)="searchUser()"
                                    placeholder="Enter Member ID"
                                    aria-label="Member ID"
                                    aria-describedby="button-addon2"
                                  />
                                </div>
                              </div>
                            </div>
                            <div *ngIf="loading">
                              <p>Loading...</p>
                            </div>
                            <div *ngIf="notFound">
                              <p>Member not found</p>
                            </div>
                            <div *ngIf="user">
                              <div class="col-sm-12">
                                <div class="mb-3 row">
                                  <label
                                    for="userID"
                                    class="col-sm-2 col-form-label"
                                    >User ID</label
                                  >
                                  <div class="col-sm-10">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="userID"
                                      value=""
                                      disabled
                                      [(ngModel)]="user.user_Id"
                                    />
                                  </div>
                                </div>
                                <div class="mb-3 row">
                                  <label
                                    for="churchID"
                                    class="col-sm-2 col-form-label"
                                    >Church ID</label
                                  >
                                  <div class="col-sm-10">
                                    <input
                                      type="text"
                                      disabled=""
                                      class="form-control"
                                      id="churchID"
                                      [(ngModel)]="user.churchID"
                                    />
                                  </div>
                                </div>
                                <div class="mb-3 row">
                                  <label
                                    for="username"
                                    class="col-sm-2 col-form-label"
                                    >Username</label
                                  >
                                  <div class="col-sm-10">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="username"
                                      [(ngModel)]="user.username"
                                    />
                                  </div>
                                </div>
                                <div class="mb-3 row">
                                  <label
                                    for="password"
                                    class="col-sm-2 col-form-label"
                                    >Password</label
                                  >
                                  <div class="col-sm-10">
                                    <input
                                      type="password"
                                      class="form-control"
                                      id="password"
                                      [(ngModel)]="user.password"
                                    />
                                  </div>
                                </div>
                                <div class="mb-3 row">
                                  <label
                                    for="firstName"
                                    class="col-sm-2 col-form-label"
                                    >First Name</label
                                  >
                                  <div class="col-sm-10">
                                    <input
                                      type="text"
                                      disabled
                                      class="form-control"
                                      id="firstName"
                                      [(ngModel)]="user.firstName"
                                    />
                                  </div>
                                </div>
                                <div class="mb-3 row">
                                  <label
                                    for="lastName"
                                    class="col-sm-2 col-form-label"
                                    >Last Name</label
                                  >
                                  <div class="col-sm-10">
                                    <input
                                      type="text"
                                      disabled=""
                                      class="form-control"
                                      id="lastName"
                                      [(ngModel)]="user.lastName"
                                    />
                                  </div>
                                </div>
                                <div class="mb-3 row">
                                  <label
                                    for="accessLevel"
                                    class="col-sm-2 col-form-label"
                                    >Access Level</label
                                  >
                                  <select
                                    name="access_level"
                                    [(ngModel)]="user.access_level"
                                  >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h5>By Saving you agree to make a member an admin</h5>
                        </div>
                        <div class="modal-footer p-1">
                          <button
                            type="button"
                            class="btn btn-secondary p-1 m-1"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="btn btn-success p-1"
                            (click)="saveUser()"
                          >
                            Save Member
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                   <!--End --- Modal to ADD Admin Member   ------------->

                </tbody>
              </table>
              <div class="text-center mt-5" *ngIf="!allAdminUsers">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal  -->

    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">...</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Understood</button>
          </div>
        </div>
      </div>
    </div>
    <!-- ends here  -->
  </main>

  <div id="sidebar" [ngClass]="status ? 'sidebar_responsive' : ''">
    <div class="sidebar__title">
      <div class="sidebar__img">
        <h1>Admin</h1>
      </div>
      <i
        (click)="addToggle()"
        class="fa fa-times"
        id="sidebarIcon"
        aria-hidden="true"
      ></i>
    </div>
    <
    <div class="sidebar__menu">
      <app-side-nav></app-side-nav>
    </div>
  </div>
</div>
