import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-members-transfered-out',
  templateUrl: './members-transfered-out.component.html',
  styleUrls: ['../dashbboard-style.css']
})
export class MembersTransferedOutComponent implements OnInit {
  status = false;
  members: any;
  addToggle() {
    this.status = !this.status;
  }


  allTransferdMembersOut: any;

  constructor(private service: SharedService) {}

  getTransferedMembersOut() {
    this.service.GetTransferedMembersOut()
    .subscribe((r) => {
      this.allTransferdMembersOut = r;
      if (this.allTransferdMembersOut.length <=0) {
        this.members = "No Members";
      }
    // console.log('trans', this.allTransferdMembersOut)
    });
  }

  ngOnInit(): void {
    this.getTransferedMembersOut(); 
  } 

}
