import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Alert } from 'src/app/shared/message-helper';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css', '../dashbboard-style.css']
})

export class AdminUsersComponent implements OnInit {
  allAdminUsers: any;

  status = false;

  searchQuery: string='';
  loading: boolean = false;
  user: any;
  notFound: any;

  
  addToggle() {
    this.status = !this.status;
  }
  constructor(private service:SharedService) { }

  getAdminMembersList(){
    this.service.GetAdminMembersList().subscribe(r => {
      this.allAdminUsers = r
      // console.log('data ---- -----',this.allAdminUsers);
    })
}

searchUser(): void {
  if (this.searchQuery && this.searchQuery.length >= 4) {
    this.loading = true;
    this.service.getMemberDetails(this.searchQuery).subscribe(
      (response) => {
        this.user = response;
        // console.log('found ----------------', this.user);
        this.loading = false;
      },
      (error) => {
        // console.error(error);
        this.notFound = error;
        // Handle error here
        this.loading = false;
      }
    );
  } else {
    this.user = null;
  }
}
  saveUser(): void {
    this.service.saveUser(this.user)
      .subscribe(
        response => {
          Alert.success('User saved successfully');
        },
        error => {
          // console.log('Error saving user:', error);
        }
      );
  }

  deleteAdminMember(memberId:any){
    this.service.DeleteAdminMember(memberId).subscribe(r => {
      Alert.error("Record deleted successfully. ");
      // this.route.navigate(['/members-list']);
      window.location.reload();
      this.getAdminMembersList();
      // this.route.navigate(['/members-list']);
      });
    }

    // updateAdminMember(memberId:any, updatedInfo: any){
    //   this.service.updatedAdminMember(memberId, updatedInfo).subscribe(r => {
    //     // Alert.success("Record updated successfully. ");
    //     console.log("workinf")
    //     // this.route.navigate(['/members-list']);
    //     // window.location.reload();
    //     this.getAdminMembersList();
    //     // this.route.navigate(['/members-list']);
    //     }, error => {
    //       console.log(error)
    //     });
    //   }

    updateAdminMember(updatedUser: any) {
    this.service.updateAdminMember(updatedUser).subscribe(
      (data) => {
        if (data) {
          Alert.success('Admin updated successfully');
            window.location.reload();
          // console.log('Admin member updated successfully', data.error.error.text);
        } else {
          Alert.error("something wenth wrong")
          // console.log('User not found');
        }
      }
    );
  }
// }




  ngOnInit(): void {
    this.getAdminMembersList();
  }
}
