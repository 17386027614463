import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Alert } from 'src/app/shared/message-helper';

@Component({
  selector: 'app-members-confirmed',
  templateUrl: './members-confirmed.component.html',
  styleUrls: ['../dashbboard-style.css']
})
export class MembersConfirmedComponent implements OnInit {

  status = false;
  addToggle() {
    this.status = !this.status;
  }

  allConfirmMembers: any;
  confirmMembers: any;

  constructor(private service:SharedService, private route: Router) { }

  // getConfirmedMembers() {
  //   this.service.GetUnConfirmedMembers().subscribe((r) => {
  //     this.confirmMembers = r;
  //     console.log(this.confirmMembers, -'----->>')
  //   });
  // }
  getConfirmedMembers(){
    this.service.GetConfirmedMembers().subscribe(r => {
      this.allConfirmMembers = r
      // console.log('okay', this.allConfirmMembers)
    })
}


  unapproveMember(memberId: any) {
    this.service.DeActiviate(memberId).subscribe((r) => {
      // console.log("member approve", r);
      Alert.success("Member no more approved");
      this.getConfirmedMembers();
    },
    error => {
      
      Alert.error("Member not  Approved");
      // console.log("----->", error);
      this.getConfirmedMembers();
      // this.route.navigate(['/members-list']);
    }  
   
  );
    // this.service.unApproveMember(memberId).subscribe((r:any[]) => {
    //     console.log("approve", r[0]);
    //     alert("Member Successfully Approved ----");
    //     Alert.success("Member Successfully Approved");
    //   },
    //   (error) => {
        
    //     Alert.success("Member not Approved due to some error");
    //     console.log("----->", error.error.text);
    //     this.route.navigate(['/members-list']);
    //   }  
     
    // );
    // this.confirmed  = true,
  }

  deleteMember(memberId:any){
    this.service.deleteMember(memberId).subscribe(r => {
      Alert.error("Record deleted successfully. ");
      this.getConfirmedMembers();
      // this.deleted = true
      // if(r.length == 0){
        // Alert.success("Record deleted successfully ");
        // this.getUnconfirmedMembers();
        // console.log('deleted')
      // }
      // this.route.navigate(['/members-list']);
      // this.deleted = false;
    });
  
    // console.log('okay')
  }

  ngOnInit(): void {
    this.getConfirmedMembers();
    // Alert.success("hello world");
    // alert('hello woooorld')
  }

}
