import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LoaderService } from 'src/app/loader-service.service';
import { SharedService } from 'src/app/shared.service';
// import { LoaderService } from 'src/app/shared.service';
import { Alert } from 'src/app/shared/message-helper';
import { WebUtils } from 'src/app/shared/web-utils';
import { keys } from 'underscore';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userdata: any;
  memberForm!: FormGroup;
  churchPositions: any = [];
  homecellGroup: any = [];
  biblestudyGroup: any = [];
  ministryGroup: any = [];
  selectedMinistryGroup: any[] = [];
  churchService: any = [];
  Titles: any = [];
  AcademicLevel: any = [];
  DependantList: any[] = [];
  OurdataForLoop: any;
  selectedLevel: any;
  churchID: any;
  churchId: any;
  churchName: any;
  memberID: any;
  MemberID: any;
  ChurchID: any;
  Title: any;
  FirstName: any;
  MiddleName: any;
  LastName: any;
  Gender: any;
  DOB: any;
  MinistryGroup: any;
  Age: any;
  MaritalStatus: any;
  ResidentialAddress: any;
  Landmark: any;
  DigitalAddress: any;
  PostalAddress: any;
  Email: any;
  PhoneNumber: any;
  MemberStatus: any;
  Employment: any;
  Occupation: any;
  Service: any;
  ChurchGroups: any;
  PositionInChurch: any;
  BibleStudyGroup: any;
  HomeCellGroup: any;
  // Photo : any;
  Status: any;
  inactive_Reason: any;
  Baptized: any;
  BaptizmaDate: any;
  MarriageDate: any;
  EntryDate: any;
  EntryID: any;
  ModifiedDate: any;
  ModifiedBy: any;
  FPTemplate: any;
  Remarks: any;
  Communicant: any;
  HolySpiritBaptism: any;
  Transfered: any;
  TransferedDate: any;
  TransferedToFrom: any;
  Deceased: any;
  Dateofdeath: any;
  Officer: any;
  DependantID: any;
  Relation: any;
  imageFile!: File;
  thumbnail: string = "assets/avatar.png";
  loadSpinner: any = false;
  btnSpinner: any = false;
  clicked = false;

  actionMethod(event: any) {
    event.target.disabled = true;
    event.target.disabled = false;
  }

  ActivateEditDependant: boolean = false;
  editDep: any;

  @Input() set disableControl(
    condition: boolean) {
    // const action=condition? 'disable' : 'enable';
    // this.ngControl.control[action]();
  }
  constructor(private fb: FormBuilder, private service: 
    SharedService, private route: Router,
     private loaderService: LoaderService) { }
  item: any;
  activateModal: boolean = false

  ngOnInit(): void {
    this.getData();
    this.load();
    this.loaderService.showLoader();
    this.getMemberDetails()
  }

  load() {

  }

  getData() {
    this.enableForm();
    this.getPosition();
    this.getHomeCellGroup();
    this.getBibleStudyGroup();
    this.getMinstryGroup();
    this.getChurchService();
    this.churchDetails();
    this.getMemberDetails();
    this.setDOB();
    this.getAcademicLevel();
    this.getTitle();
    this.getDependants();
  }

  editClick(item: any) {
    this.item = item;
    this.activateModal = true;
    // console.log("edit dependant here>>>>>>****",this.editDep);
  }

  closeModal() {
    this.activateModal = false;
  }
  closeModal1() {
    this.activateModal = false;
  }

  setDOB(dob?: Date) {

    var age = moment().diff(dob, 'years');
    this.memberForm.patchValue({ age })
  }

  onFileChanged(event: any) {
    this.imageFile = event.target.files[0]
    // console.log(this.imageFile)

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        // this.setState({image: e.target.result});
        this.setState(e.target?.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }

  }

  setState(image: any) {
    // console.log(image)
    this.thumbnail = image;
  }

  logout() {
    this.service.logOut();
    this.route.navigate(['/admin-login'])
  }


  updateMemberInfo(member: any) {
    this.btnSpinner = true;
    member.churchGroups = member.churchGroups?.toString();
    const formData = new FormData();
    formData.append('file', this.imageFile, this.imageFile?.name);
    keys(member).forEach((k) => {
      formData.append(k, member[k])
    });
    this.service.updateMember(formData).subscribe(data => {
      if (data) {

        Alert.success("Record successfully saved");
        this.memberForm.reset();
        this.getData();
        this.btnSpinner = false;
        // console.log('xxxxxx', data)
      }
    }, (error: any) => Alert.error("An error was encountered."));

  }

  base64ToBlob(b64: string) {
    const byteString = window.atob(b64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  addDependant() {
    this.activateModal = true;
  }
  editMemberInfo() {
    this.enableForm();
  }

  selectedPosition() {
    // console.log('------- her', this.selectedLevel.Positions)
  }

  async getDependants() {
    var usdata = localStorage.getItem("adminLogin");
    this.userdata = JSON.parse(<any>usdata);
    let memberid = this.userdata.memberID;
    this.memberID = memberid;
    this.service.getDependantList(memberid).subscribe(data => {
      this.DependantList = data;
      // console.log("dependant >> ", data);

    });
  }

  async getPosition() {
    var usdata = localStorage.getItem("adminLogin");
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    let memberid = this.userdata.memberID;
    this.churchID = churchid;
    this.memberID = memberid;
    this.service.getPositions(churchid).subscribe(data => {
      this.churchPositions = data;
    });
  }

  churchDetails() {
    this.service.getChurchByID(this.churchID).subscribe(churchData => {
      // console.log("my church details<<<<<", churchData[0]);
      this.churchName = churchData[0].Name;
      this.churchId = churchData[0].branchName;
    });
  }


  getMemberDetails() {
    this.loadSpinner = true;
    this.service.getMemberDetails(+this.memberID).subscribe(memberData => {
      // memberData.dob = WebUtils.getIsoDateString(memberData.dob);
      memberData.dob = memberData.dob;
      memberData.churchGroups = memberData.churchGroups?.split(',');
      if (memberData.photo != "") {
        this.thumbnail = `data:image/png;base64,${memberData.photo}`;
        let blob = this.base64ToBlob(memberData.photo)
        this.imageFile = new File([blob], 'profilePhoto', { type: 'image/png' });
      } else {
        this.thumbnail = "assets/avatar.png";
      }
      this.memberForm.patchValue(memberData);
      this.loadSpinner = false;
      console.log('-----member Data', memberData)
      this.setDOB(new Date(memberData.dob))
    });
  }


  async getHomeCellGroup() {

    var usdata = localStorage.getItem("adminLogin");
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.HomeCellGroup(churchid).subscribe(data => {
      this.homecellGroup = data;
    });
  }

  async getBibleStudyGroup() {
    var usdata = localStorage.getItem("adminLogin");
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.BibleStudyGroup(churchid).subscribe(data => {
      this.biblestudyGroup = data;
    });
  }

  async getAcademicLevel() {
    var usdata = localStorage.getItem("adminLogin");
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.getAcademicLevel(churchid).subscribe(data => {
      this.AcademicLevel = data;
    });
  }
  async getTitle() {
    var usdata = localStorage.getItem("adminLogin");
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.getTitles(churchid).subscribe(data => {
      this.Titles = data;
    });
  }

  async getMinstryGroup() {
    var usdata = localStorage.getItem("adminLogin");
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.MinistryGroup(churchid).subscribe(data => {
      this.ministryGroup = data;
    });
  }

  async getChurchService() {
    var usdata = localStorage.getItem("adminLogin");
    this.userdata = JSON.parse(<any>usdata);
    let churchid = this.userdata.churchID;
    this.service.churchService(churchid).subscribe(data => {
      this.churchService = data;
    });
  }


  enableForm() {
    this.memberForm = this.fb.group({
      memberID: new FormControl({ value: null, disabled: false }),
      churchID: new FormControl({ value: null, disabled: false }),
      title: new FormControl({ value: null, disabled: false }),
      firstName: new FormControl({ value: null, disabled: false }),
      middleName: new FormControl({ value: null, disabled: false }),
      lastName: new FormControl({ value: null, disabled: false }),
      gender: new FormControl({ value: null, disabled: false }),
      dob: new FormControl({ value: null, disabled: false }),
      ministryGroup: new FormControl({ value: null, disabled: false }),
      age: new FormControl({ value: null, disabled: false }),
      maritalStatus: new FormControl({ value: null, disabled: false }),
      residentialAddress: new FormControl({ value: null, disabled: false }),
      landmark: new FormControl({ value: null, disabled: false }),
      digitalAddress: new FormControl({ value: null, disabled: false }),
      postalAddress: new FormControl({ value: null, disabled: false }),
      email: new FormControl({ value: null, disabled: false }),
      phoneNumber: new FormControl({ value: null, disabled: false }),
      memberStatus: new FormControl({ value: null, disabled: false }),
      employment: new FormControl({ value: null, disabled: false }),
      occupation: new FormControl({ value: null, disabled: false }),
      educationLevel: new FormControl({ value: null, disabled: false }),
      service: new FormControl({ value: null, disabled: false }),
      churchGroups: new FormControl({ value: null, disabled: false }),
      positionInChurch: new FormControl({ value: null, disabled: false }),
      bibleStudyGroup: new FormControl({ value: null, disabled: false }),
      homeCellGroup: new FormControl({ value: null, disabled: false }),
      // Photo : new FormControl({ value: null, disabled: false }),
      status: new FormControl({ value: null, disabled: false }),
      inactive_Reason: new FormControl({ value: null, disabled: false }),
      baptized: new FormControl({ value: null, disabled: false }),
      baptizmaDate: new FormControl({ value: null, disabled: false }),
      marriageDate: new FormControl({ value: null, disabled: false }),
      entryDate: new FormControl({ value: null, disabled: false }),
      entryID: new FormControl({ value: null, disabled: false }),
      modifiedDate: new FormControl({ value: null, disabled: false }),
      modifiedBy: new FormControl({ value: null, disabled: false }),
      fPTemplate: new FormControl({ value: null, disabled: false }),
      remarks: new FormControl({ value: null, disabled: false }),
      communicant: new FormControl({ value: null, disabled: false }),
      holySpiritBaptism: new FormControl({ value: null, disabled: false }),
      transfered: new FormControl({ value: null, disabled: false }),
      transferedDate: new FormControl({ value: null, disabled: false }),
      transferedToFrom: new FormControl({ value: null, disabled: false }),
      deceased: new FormControl({ value: null, disabled: false }),
      dateofdeath: new FormControl({ value: null, disabled: false }),
      officer: new FormControl({ value: null, disabled: false }),
      // dependantID: new FormControl({ value: null, disabled: false }),
      relation: new FormControl({ value: null, disabled: false })
    });
  }

}
