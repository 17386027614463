import { NewComponent } from './admin/new/new.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { LoginComponent } from './admin/login/login.component';
import { MembersTransferedComponent } from './admin/members-transfered/members-transfered.component';
import { MembersDeceasedComponent } from './admin/members-deceased/members-deceased.component';
import { NotConfirmedComponent } from './admin/not-confirmed/not-confirmed.component';
import { MembersConfirmedComponent } from './admin/members-confirmed/members-confirmed.component';
import { MembersInactiveComponent } from './admin/members-inactive/members-inactive.component';
import { MembersActiveComponent } from './admin/members-active/members-active.component';
import { MembersListComponent } from './admin/members-list/members-list.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { MemberUpdateComponent } from './member-update/member-update.component';
import { SignInSignUpComponent } from './sign-in-sign-up/sign-in-sign-up.component';
import { MainAdminDashboardComponent } from './admin/main-admin-dashboard/main-admin-dashboard.component';
import { AdultMembersComponent } from './admin/adult-members/adult-members.component';
import { ChildrenMembersComponent } from './admin/children-members/children-members.component';
import { MembersTransferedOutComponent } from './admin/members-transfered-out/members-transfered-out.component';
import { AddDependantComponent } from './add-dependant/add-dependant.component';
// import { ComponentTemplateComponent } from './admin/component-template/component-template.component';
// import { AdminLoginComponent } from './admin/admin-login/admin-login.component';


const routes: Routes = [
  { path: '', redirectTo: '/register', pathMatch: 'full' },
  { path: 'register', component: SignInSignUpComponent },
  {path:'updateMember', component:MemberUpdateComponent},
  {path: 'dashboard', component: DashboardComponent},
{path: 'MainAdminDashboard', component: MainAdminDashboardComponent},
{path: 'AddDependant', component: AddDependantComponent},
  // {path:'admin-login2', component:AdminLoginComponent},

  // dashboard 
  {path: 'members-list', component: MembersListComponent},
  {path: 'active-members', component: MembersActiveComponent},
  {path: 'members-in-active', component: MembersInactiveComponent},
  {path: 'members-confirmed', component: MembersConfirmedComponent},
  {path: 'unconfirmed-members', component: NotConfirmedComponent},
  {path: 'member-deceased', component: MembersDeceasedComponent},
  {path: 'member-transferd', component: MembersTransferedComponent},
  {path: 'member-transferd-out', component: MembersTransferedOutComponent},
  {path: 'admin-login', component: LoginComponent},
  {path: 'reports', component: ReportsComponent},
  
  {path: 'admin-profile', component: ProfileComponent},
  {path: 'admin-users', component: AdminUsersComponent},
  {path: 'adult-members', component: AdultMembersComponent},
  {path: 'children-members', component: ChildrenMembersComponent},

  {path: 'new', component: NewComponent},
  {path: '**', component: ErrorComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// "src/_redirects"
