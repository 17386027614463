import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-members-deceased',
  templateUrl: './members-deceased.component.html',
  styleUrls: ['../dashbboard-style.css','./members-deceased.component.css']
})
export class MembersDeceasedComponent implements OnInit {

  status = false;
  addToggle() {
    this.status = !this.status;
  }
  allDeceasedMembers: any;

  constructor(private service: SharedService) {}

  getDeceasedMembers() {
    this.service.GetDeceasedMembers().subscribe((r) => {
      this.allDeceasedMembers = r;
    });
  }
  ngOnInit(): void {
    this.getDeceasedMembers();
  }

}
