import { SharedService } from './../../shared.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Alert } from 'src/app/shared/message-helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.css', '../dashbboard-style.css'],
})
export class MembersListComponent implements OnInit {
  @ViewChild('deleteModal') deleteModal: any;

  status = false;
  addToggle() {
    this.status = !this.status;
  }

  allMembers: any;
  deleted: any;
  allDashboardData: any;

  allUnconfirmMembers: any;
  allConfirmMembers: any;
  confirmed: any = false;

  memberstatus = 'Active'; // or 'inactive' or 'unconfirm'

  constructor(private service: SharedService, private route: Router) {}

  activeUsers: any[] | undefined;
  inactiveUsers: any[] | undefined;
  unconfirmedUsers: any[] | undefined;

  allMemebresRegistered: any;
  loading: any;
  getMembersByStats(memberstatus: any) {
    this.service.GetMemberByStatus(memberstatus).subscribe((r) => {
      this.allMembers = r;
      console.log('membrer listj >>>> ---- -----', this.allMembers);
    });
  }

  // getAllMembers() {
  //   this.loading = true; // Set loading to true before making the request
  //   this.service.GetAllMembers().subscribe(
  //     (r) => {
  //       this.allMemebresRegistered = r;
  //       console.log('<<data ---- -----', this.allMemebresRegistered);
  //       this.loading = false; // Set loading to false after successfully receiving the data
  //     },
  //     (error) => {
  //       console.log('Error occurred:', error);
  //       this.loading = false; // Set loading to false in case of an error
  //     }
  //   );
  // }

  // this will return all member Members Inactive, unconfirmed
  getMembersListing(): void {
    this.loading = true;
    this.service.getMembersListing().subscribe((r) => {
      this.allMemebresRegistered = r;
      // console.log('<<look here ---- -----', this.allMemebresRegistered);
      this.loading = false; // Set loading to false after successfully receiving the data
    });
  }

  getAllUsers() {
    this.service
      .GetAllMembers()
      .subscribe(([activeUsers, inactiveUsers, unconfirmedUsers]) => {
        this.activeUsers = activeUsers;
        this.inactiveUsers = inactiveUsers;
        this.unconfirmedUsers = unconfirmedUsers;
      });
  }

  // deleteMember(memberId: any) {
  //   this.service.deleteMember(memberId).subscribe((r) => {
  //     // this.deleted = true
  //     if (r.length == 0) {

  //       Alert.success('Record deleted successfully ');
  //       // console.log('deleted')
  //     }
  //   });

  //   // console.log('okay')
  // }

  ApproveMember(memberId: any) {
    this.service.approveMember(memberId).subscribe(
      (r) => {
        console.log('approve', r);
        this.getMembersListing();
        // this.route.navigate(['/members-confirmed']);
        Alert.success('Member Successfully Confirmed');
        // alert("")
      },
      (error) => {
        console.log('----->', error);
        // this.route.navigate(['/members-list']);
      }
    );
    // this.confirmed  = true,
  }

  getUnconfirmedMembers() {
    this.service.GetUnConfirmedMembers().subscribe((r) => {
      this.allUnconfirmMembers = r;
    });
  }

  DeActivateMember(memberId: any) {
    this.service.DeActiviate(memberId).subscribe(
      (r) => {
        console.log('approve', r);
        Alert.success('Member De-Activated Successfully');
        // alert("")
        this.getMembersListing();
      },
      (error) => {
        console.log('----->', error);
        // this.route.navigate(['/members-list']);
      }
    );
  }

  deleteMember(memberId: any) {
    this.service.deleteMember(memberId).subscribe((r) => {
      Alert.error('Record deleted successfully. ');
      // this.route.navigate(['/members-list']);
      window.location.reload();
      this.getMembersListing();
      // this.route.navigate(['/members-list']);
    });
  }

  ngOnInit(): void {
    // this.getMembersList();
    // this.getAllMembers();
    this.getMembersListing();
    // this.getMembers();
  }
}
