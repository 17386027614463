import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Alert } from 'src/app/shared/message-helper';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  allMembers: any;
  deleted:any;

  constructor(private service:SharedService,
    private route: Router) { }

  getMembersList(){
    this.service.GetMembersList().subscribe(r => {
      this.allMembers = r
      // console.log('data ---- -----',this.allMembers)
    })
}

deleteMember(memberId:any){
  this.service.deleteMember(memberId).subscribe(r => {
   
    // this.deleted = true
    if(r.length == 0){
      Alert.success("Record deleted successfully ");
      // console.log('deleted')
    }
    // this.route.navigate(['/members-list']);
    // this.deleted = false;
  })

  // console.log('okay')
}

print(){
  window.print();
  // console.log('print invoked')
}

  ngOnInit(): void {
    this.getMembersList();
    // this.deleteMember();
  }

}
