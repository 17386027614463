
<div class="container">
  <nav class="navbar">
    <div class="nav_icon" (click)="addToggle()">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
    <div class="navbar__left">
      <!-- <a href="#">Subscribers</a>
      <a href="#">Video Management</a> -->
      <a class="active_link" href="#">Admin</a>
    </div>
    <div class="navbar__right">
      <a href="#">
        <i class="fa fa-search" aria-hidden="true"></i>
      </a>
      <a href="#">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </a>
      <a href="#">
         <img width="30" src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g">
        
      </a>
    </div>
  </nav>
  <main>
    <div class="main__container">
      <!-- MAIN TITLE STARTS HERE -->
      <div class="main__title">
        <div class="main__greeting">
          <!-- <h1>Hello Admin</h1> -->
          <!-- <h4>Welcome to your admin dashboard</h4> -->
        </div>
      </div>
    
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <!-- <div class="container px-4 py-5" id="custom-cards"> -->
              <h2 class="pb-2 border-bottoms">Active Members</h2>
              <div class="table-responsive">
                <table class="table table-stripeds table-sm">
                  <!-- <thead> -->
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Gender</th>
                      <!-- <th scope="col">Age</th> -->
                      <th scope="col">Bible Study Group</th>
                      <!-- <th scope="col">Baptized</th> -->
                      <th scope="col">Position</th>
                      <th scope="col">Address</th>
                      <!-- <th scope="col">view</th>  -->
    
                    </tr>
                  <!-- </thead> -->
                  <tbody >
                    <tr *ngFor="let user of activeMembers">
                      <td>{{user.memberID}}</td>
                      <td>{{user.firstName}} {{user.middleName}} {{user.lastName}}</td>
                      <td>{{user.phoneNumber}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.gender}}</td>
                      <!-- <td>{{user.age}}</td> -->
                      <td>{{user.bibleStudyGroup == "null" ? " "  : user.bibleStudyGroup}}</td>
                      <!-- <td>{{user.baptized}}</td> -->
                      <td>{{user.positionInChurch}}</td>
                      <td>{{user.residentialAddress}}</td>
                      <td>
                            <!-- <i class="far fa-edit" ></i> -->
                            <!-- <i class="far fa-eye" ></i> -->
                            <button class="btn btn-dark p-1" (click)="deActivateMember(user.memberID)">De-Activate</button>
                      <!-- <i class="fas fa-trash text-danger" (click)="deleteMember(user.memberID)"></i>  -->
    
                       </td>
                    </tr>
                  </tbody>
                 
               
                </table>
                <div class="text-center mt-5" *ngIf="isLoading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
      

    </div>
  </main>
  
  <div id="sidebar" [ngClass]="status ? 'sidebar_responsive' : ''">
    <div class="sidebar__title">
      <div class="sidebar__img">
        <h1>Admin</h1>
      </div>
      <i
      (click)="addToggle()"
        class="fa fa-times"
        id="sidebarIcon"
        aria-hidden="true"
      ></i>
    </div>
   < <div class="sidebar__menu">
     <app-side-nav></app-side-nav>
    </div>
  </div>
</div>




   